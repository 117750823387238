import Button from "../form/Button";
import React from "react";
import {PropTypes} from 'prop-types';

export default function StepNavigationButtons(props) {

    const nextButtonLabel = props.nextButtonText ? props.nextButtonText : "Next Page";
    const previousButtonLabel = props.previousButtonText ? props.previousButtonText : "Previous Page";

    return (
        <div className="container row util-padding-top-10">
            {props.onPreviousPage &&
                <div className="pull-left">
                    <Button text={previousButtonLabel} onClick={props.onPreviousPage} loading={props.loading} />
                </div>
            }
            {props.onNextPage &&
                <div className="pull-right">
                    <Button type="submit" text={nextButtonLabel} loading={props.loading}/>
                </div>
            }
        </div>
    );
}

StepNavigationButtons.propTypes = {
    onPreviousPage: PropTypes.any,
    onNextPage: PropTypes.any,
    nextButtonText: PropTypes.string,
    previousButtonText: PropTypes.string
};
