import React, {useState} from "react";
import InputBox from "../form/InputBox";
import Button from "../form/Button";
import {searchFinancialProfessionals} from "../../data/api";
import DataTable from "../form/DataTable";
import {getFinancialProfessionalName, isEncoded} from "../../data/utils";
import {useDispatch, useSelector} from "react-redux";
import {actions} from "../../data/reducer";


const FinancialProfessionalSearch = (props) => {
    const {
        onSelection
    } = props;

    const workforceId = useSelector(state => state.form.workforceId);

    const dispatch = useDispatch();

    const [lastName, setLastName] = useState("")
    const [firstName, setFirstName] = useState("")
    const [crdId, setCrdId] = useState("")
    const [taxId, setTaxId] = useState("")

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState('')

    const [searchRan, setSearchRan] = useState(false)

    const selectFinancialProfessionalButton = (financialProfessional) => {
        return (
            <Button
                className={"pds-button"}
                text={"Select"}
                type={'submit'}
                onClick={() => onSelection(financialProfessional)}
            />
        )
    }
    const getFinancialProfessionals = () => {
        if (isValid()) {
            actions.clearErrors(dispatch)
            setLoading(true)

            const searchCriteria = {
                firstName,
                lastName,
                taxId,
                crdId,
                workforceId
            }
            searchFinancialProfessionals(searchCriteria, data => {
                setData(data.data.map(row => {
                    let fp = row
                    return [getFinancialProfessionalName(fp), fp.fpCrdId, selectFinancialProfessionalButton(fp)]
                }));
                actions.clearErrors(dispatch)
                setLoading(false)
                setSearchRan(true)
            }, error => {
                actions.updateErrors(dispatch, error)
                setLoading(false)
                setSearchRan(true)
            })
        } else {
            if (isValidInput() === false) {
                actions.updateErrors(dispatch, [{
                    field: "none",
                    error: "Search criteria has invalid characters"
                }])
            } else {
                actions.updateErrors(dispatch, [{field: "none", error: "Search criteria is required"}])
            }
        }
    }

    function isValid() {
        return (lastName || firstName || crdId || taxId) && isValidInput();
    }

    function isValidInput() {
        return isEncoded(lastName) &&
            isEncoded(firstName) &&
            isEncoded(crdId);
    }

    return (
        <>
            <div className={"util-padding-bottom-10"}>Search with any criteria you may have. You must enter at least one
                character in any field
            </div>
            <form>
                <div className="row">
                    <InputBox
                        id="lastName"
                        label={"Last name"}
                        length="6"
                        value={lastName}
                        onChange={setLastName}
                    />

                    <InputBox
                        id="firstName"
                        label={"First name"}
                        length="6"
                        value={firstName}
                        onChange={setFirstName}
                    />

                </div>
                <div className="row">
                    <InputBox
                        id="crd"
                        label={"CRD number"}
                        length="6"
                        value={crdId}
                        onChange={setCrdId}
                    />

                    <InputBox
                        id="ssn"
                        label={"Social security number"}
                        length="6"
                        value={taxId}
                        onChange={setTaxId}
                        integerOnly={true}
                    />

                </div>
                <div className="row util-padding-top-10 util-padding-left-15">
                    <Button
                        loading={loading}
                        onClick={(e) => e.preventDefault() || getFinancialProfessionals()}
                        type='submit'
                        text='Search'
                    />
                </div>
            </form>
            {searchRan && data.length === 0 &&
            <div className="panel-body text-center">
                No results were found. Refine your search.
            </div>
            }
            {data.length > 0 &&
            <DataTable
                title={"Financial Professionals"}
                id={"financial-professional-search-results"}
                columnHeaders={["Name", "CRD", "Select"]}
                data={data}
            />
            }
        </>
    )
}

export default FinancialProfessionalSearch;