import './App.css';
import React from "react";
import {AuthProvider} from "@principal-aws-oidc-auth/react";
import Page from "./components/Page";
import {applyMiddleware, createStore} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import {initialState, reducer} from "./data/reducer";
import {Provider} from "react-redux";
import thunk from "redux-thunk";

export default function App() {

    const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(thunk)));
    const clientId = window.location.hostname === 'psicheckblotter.principal.com' ? 'c99f262e-9497-411d-90c1-7793c46b87f7' : '21a53bdf-e89f-44f2-80e3-47b29cdc78be';
    const tenantId = window.location.hostname === 'psicheckblotter.principal.com' ? '3bea478c-1684-4a8c-8e85-045ec54ba430' : '50abac53-da5d-44dc-8586-587ec2d3d097';

    return (
        <AuthProvider settings={{
            authority: `https://login.microsoftonline.com/${tenantId}/v2.0`,
            client_id: clientId,
            scope: 'api://psi-check-blotter/read api://psi-check-blotter/write'
        }}>
            <Provider store={store}>
                <Page />
            </Provider>
        </AuthProvider>
    );
}