import React, {useEffect, useState} from "react";
import ClientInformation from "./ClientInformation";
import {PropTypes} from "prop-types";
import {useSelector} from "react-redux";
import {omit, slice} from "lodash";
import CheckBox from "../form/CheckBox";
import InputBox from "../form/InputBox";
import Select from "../form/Select";
import Button from "../form/Button";
import {getDocumentText, isEncoded, stringEmpty} from "../../data/utils";
import DollarAmountInputBox from "../form/DollarAmountInputBox";
import {DOCUMENT_TYPE_STOCK, GENERAL_BROKERAGE_ID, PSI_ADVISORY_ID} from "../../data/constants";

function setIsValid(setProductFunding, productFunding, isValid) {
    setProductFunding({...productFunding, isValid: isValid});
}

function setAccountNumber(setProductFunding, productFunding, accountNumber) {
    setProductFunding({...productFunding, product: {...productFunding.product, accountNumber: accountNumber}});
}

function setDepositAmount(setProductFunding, productFunding, depositAmount) {
    setProductFunding({...productFunding, depositAmount: depositAmount});
}

function removeDepositAmount(setProductFunding, productFunding) {
    const newProductFunding = omit(productFunding, ['depositAmount']);
    setProductFunding(newProductFunding);
}

export default function ProductInformation(props) {

    const productTypes = useSelector(state => state.domains.productTypes || []);
    const workforceId = useSelector(state => state.form.workforceId);

    const documentType = useSelector(state => state.form.documentType)

    const [accountNumberError, setAccountNumberError] = useState('');
    const [productTypeError, setProductTypeError] = useState('');
    const [depositAmountError, setDepositAmountError] = useState('');

    const editable = props.editable === undefined ? false : !props.editable;
    const product = props.productFunding.product;

    const filterProductTypes = () => {
        if (documentType === DOCUMENT_TYPE_STOCK) {
            return productTypes.filter((productType) => [GENERAL_BROKERAGE_ID, PSI_ADVISORY_ID].includes(productType.id))
        } else {
            return productTypes
        }
    }

    function setNewApplicationFlag(newApplicationFlag) {
        props.setProductFunding({
            ...props.productFunding,
            product: {...product, newApplicationFlag: newApplicationFlag}
        });
    }

    function setProductType(productType) {
        props.setProductFunding({...props.productFunding, product: {...product, productType: productType}});
    }

    function addNewClient() {
        props.setShowAllErrors(false)
        props.setProductFunding({
            ...props.productFunding,
            product: {...product, clients: [...product.clients, {workforceId: workforceId}]}
        })
    }

    function setClient(index, client) {
        props.setProductFunding({
            ...props.productFunding,
            product: {
                ...product,
                clients: [...slice(product.clients, 0, index), client, ...slice(product.clients, index + 1)]
            }
        });
    }

    function removeClient(index) {
        props.setProductFunding({
            ...props.productFunding,
            product: {...product, clients: [...slice(product.clients, 0, index), ...slice(product.clients, index + 1)]}
        });
    }

    function clearErrors() {
        setAccountNumberError('');
        setProductTypeError('');
        setDepositAmountError('');
    }

    useEffect(() => {
        if (product.newApplicationFlag && product.accountNumber && product.accountNumber.length !== 0 &&
            isEncoded(product.accountNumber)) {
            setAccountNumber(props.setProductFunding, props.productFunding, '');
        }
    }, [
        props.setProductFunding,
        props.productFunding,
        product.newApplicationFlag,
        product.accountNumber
    ]);

    useEffect(() => {
        if (!props.showDepositAmount && props.productFunding.depositAmount) {
            removeDepositAmount(props.setProductFunding, props.productFunding);
        }
    }, [props.showDepositAmount, props.productFunding, props.setProductFunding]);

    useEffect(() => {
        if (!product) {
            setIsValid(props.setProductFunding, props.productFunding, false);
            return;
        }
        const clientsValid = product.clients ? product.clients.every(client => client.isValid) : true;
        const productTypeValid = !stringEmpty(product.productType);
        const accountNumberValid = product.newApplicationFlag || (!stringEmpty(product.accountNumber)
            && isEncoded(product.accountNumber));

        const valid = clientsValid && accountNumberValid && productTypeValid;
        if (props.productFunding.isValid !== valid) {
            setIsValid(props.setProductFunding, props.productFunding, valid);
        }
    }, [product,
        product.clients,
        product.productType,
        product.newApplicationFlag,
        product.accountNumber,
        props.productFunding,
        props.setProductFunding,
        props.showDepositAmount,
        props.depositAmount
    ]);

    useEffect(() => {
        clearErrors();

        if (props.showAllErrors && props.showDepositAmount && stringEmpty(props.productFunding.depositAmount)) {
            setDepositAmountError(`${getDocumentText(documentType, "Deposit", "Share")} amount is required.`)
        } else if (props.showAllErrors && props.showDepositAmount && props.productFunding.depositAmount <= 0) {
            setDepositAmountError(`${getDocumentText(documentType, "Deposit", "Share")} amount cannot be zero`)
        } else if (props.showAllErrors && !props.depositAmountMatch) {
            setDepositAmountError(`${getDocumentText(documentType, "Deposit", "Share")} amounts must sum to equal ${getDocumentText(documentType, "check amount", "share total")}`)
        } else {
            setDepositAmountError('')
        }

        if (props.showAllErrors) {
            if (!product.newApplicationFlag && stringEmpty(product.accountNumber)) {
                setAccountNumberError('Account number is required.');
            } else if (!product.newApplicationFlag && !isEncoded(product.accountNumber)) {
                setAccountNumberError('Account number has invalid characters.')
            } else {
                setAccountNumberError('')
            }

            if (stringEmpty(product.productType)) {
                setProductTypeError('Product type is required.')
            } else {
                setProductTypeError('')
            }

        }
    }, [
        props,
        props.showAllErrors,
        product,
        documentType,
        props.productFunding.depositAmount,
        props.showDepositAmount,
        props.depositAmountMatch
    ])

    return (
        <>
            <div className='row util-padding-top-5'>
                <div className="col-md-4">
                    <h3 className="h3-override-margin-top">Product information</h3>
                </div>
            </div>
            <div className="row util-padding-top-10">
                <div className="col-md-6">
                    <CheckBox
                        id={props.id + '.product.newApplicationFlag'}
                        label='No account number assigned'
                        checked={product.newApplicationFlag || false}
                        onChecked={setNewApplicationFlag}
                        disabled={editable}
                    />
                </div>
            </div>
            <div className="row util-padding-top-10">
                <div className="form-group">
                    <InputBox
                        id={props.id + '.product.accountNumber'}
                        label='Account number'
                        disabled={product.newApplicationFlag}
                        length='4'
                        required={!product.newApplicationFlag}
                        value={product.accountNumber}
                        onChange={setAccountNumber.bind(null, props.setProductFunding, props.productFunding)}
                        error={accountNumberError}
                        maxLength={32}
                        readonly={editable}
                    />
                    <Select
                        id={props.id + '.product.productType'}
                        items={filterProductTypes()}
                        label='Product type'
                        length='4'
                        required={true}
                        value={props.productFunding.product.productType}
                        onChange={setProductType}
                        error={productTypeError}
                        disabled={editable}
                    />

                    {props.showDepositAmount &&
                    <DollarAmountInputBox
                        id={props.id + '.depositAmount'}
                        label={`${getDocumentText(documentType, "Deposit amount", "Share amount")}`}
                        length='4'
                        required={true}
                        decimals={documentType === DOCUMENT_TYPE_STOCK ? 6 : 2}
                        value={props.productFunding.depositAmount}
                        onChange={setDepositAmount.bind(null, props.setProductFunding, props.productFunding)}
                        error={depositAmountError}
                        readonly={editable}
                        hideDollarSign={documentType === DOCUMENT_TYPE_STOCK}
                    />
                    }
                </div>
            </div>
            <div className='row util-padding-top-10'>
                <div className="col-md-10">
                    <h3 className="h3-override-margin-top">Client(s)</h3>
                </div>
            </div>
            <div className='row util-padding-bottom-10'>
                <div className="col-md-10">
                    {props.productFunding.product && props.productFunding.product.clients
                    && props.productFunding.product.clients.map((client, index) =>
                        <ClientInformation
                            key={index} id={props.id + ".product.clients[" + index + "]"}
                            showAddAnotherClient={index === props.productFunding.product.clients.length - 1}
                            showRemoveClient={props.productFunding.product.clients.length > 1}
                            addAnotherClient={addNewClient}
                            client={client}
                            setClient={setClient.bind(null, index)}
                            removeClient={removeClient.bind(null, index)}
                            showAllErrors={props.showAllErrors}
                            editable={props.editable}
                        />
                    )
                    }
                </div>
            </div>
            {props.showRemoveProductFunding &&
            <div className='row util-padding-top-10'>
                <div className="col-md-3 util-padding-top-5 pull-right">
                    <Button
                        text='Remove product'
                        className='pull-right '
                        color='red'
                        onClick={props.removeProductFunding}
                        disabled={editable}
                    />
                </div>
            </div>
            }
        </>

    )
}

ProductInformation.propTypes = {
    id: PropTypes.string,
    required: PropTypes.bool,
    showDepositAmount: PropTypes.bool,
    productFunding: PropTypes.object,
    setProductFunding: PropTypes.func,
    removeProductFunding: PropTypes.func,
    showRemoveProductFunding: PropTypes.bool
}
