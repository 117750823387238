import ErrorWrapper from "./ErrorWrapper";
import {PropTypes} from "prop-types";
import React from "react";

export default function DecimalInputBox(props){
    const length = props.length ? props.length : "6";
    const validate = (value) => {
        // Regex removes all input that is not a number,
        // a single decimal point, or more than six numbers after the decimal
        value = value.replace(/[^0-9.]/g, '')
        try {
            const [whole, partial] = value.split(".")
            return `${whole}.${partial.substring(partial.length - 6)}`
        } catch (TypeError) {
            return value
        }
    }
    return (
        <div className={"col-md-" + length}>
            <ErrorWrapper error={props.error}>
                <label htmlFor={props.id} className={props.required ? 'is-required' : '' }>{props.label}</label>
                    <input type="text"
                       id={props.id}
                       className="form-control"
                       value={props.value || ''}
                       disabled={!!props.disabled}
                       onChange={(x) => {props.onChange(validate(x.target.value))}}
                       readOnly={!!props.readonly}
                    />
            </ErrorWrapper>
        </div>
    );

}

DecimalInputBox.propTypes = {
    length: PropTypes.string,
    id: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
}
