import React, {useEffect, useState} from "react";
import {PropTypes} from "prop-types";
import {omit} from "lodash";
import Button from "../form/Button";
import InlineRadio from "../form/InlineRadio";
import InputBox from "../form/InputBox";
import {isEncoded, stringEmpty} from "../../data/utils";
import {CLIENT_TYPE_ORG_ID, CLIENT_TYPE_PERSON_ID} from "../../data/constants";

function setIsValid(setClient, client, isValid) {
    setClient({...client, isValid: isValid});
}

export default function ClientInformation(props) {
    const editable = props.editable === undefined ? false : !props.editable;

    const [firstNameError, setFirstNameError] = useState('');
    const [middleNameError, setMiddleNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [orgNameError, setOrgNameError] = useState('');

    function setClientType(clientType) {
        props.setClient({...props.client, clientType: clientType});
    }

    function setClientFirstName(clientFirstName) {
        props.setClient({...props.client, clientFirstName: clientFirstName});
    }

    function setClientMiddleName(clientMiddleName) {
        props.setClient({...props.client, clientMiddleName: clientMiddleName});
    }

    function setClientLastName(clientLastName) {
        props.setClient({...props.client, clientLastName: clientLastName});
    }

    function setClientOrgName(clientOrgName) {
        props.setClient({...props.client, orgName: clientOrgName});
    }

    function clearClientPersonName() {
        props.setClient(omit(props.client, ['clientFirstName', 'clientMiddleName', 'clientLastName']))
    }

    function clearClientOrgName() {
        props.setClient(omit(props.client, ['orgName']))
    }


    useEffect(() => {
        if (props.client.clientType === undefined) {
            setClientType(CLIENT_TYPE_PERSON_ID);
        }
    });

    useEffect(() => {
        if (props.client.clientType === CLIENT_TYPE_PERSON_ID && props.client.orgName) {
            clearClientOrgName();
        } else if (props.client.clientType === CLIENT_TYPE_ORG_ID && (props.client.clientFirstName || props.client.clientMiddleName || props.client.clientLastName)) {
            clearClientPersonName();
        }
    });

    useEffect(() => {
        const personValid = props.client.clientType === CLIENT_TYPE_PERSON_ID
            && (!stringEmpty(props.client.clientFirstName) && isEncoded(props.client.clientFirstName))
            && (!stringEmpty(props.client.clientLastName) && isEncoded(props.client.clientLastName))
            && (stringEmpty(props.client.clientMiddleName) ||
                isEncoded(props.client.clientMiddleName));

        const orgValid = props.client.clientType === CLIENT_TYPE_ORG_ID
            && !stringEmpty(props.client.orgName)
            && isEncoded(props.client.orgName);
        
        const valid = personValid || orgValid;
        if (props.client.isValid !== valid) {
            setIsValid(props.setClient, props.client, valid);
        }
    }, [
        props.client,
        props.setClient
    ]);

    useEffect(() => {
        setFirstNameError('');
        setMiddleNameError('');
        setLastNameError('');
        if (props.showAllErrors) {
            if (props.client.clientType === CLIENT_TYPE_PERSON_ID) {

                if (stringEmpty(props.client.clientFirstName)) {
                    setFirstNameError('First name is required.');
                } else if (!isEncoded(props.client.clientFirstName)) {
                    setFirstNameError('First name has invalid characters.')
                } else {
                    setFirstNameError('');
                }
                if (!stringEmpty(props.client.clientMiddleName) && !isEncoded(props.client.clientMiddleName)) {
                    setMiddleNameError('Middle name has invalid characters.');
                } else {
                    setMiddleNameError('');
                }

                if (stringEmpty(props.client.clientLastName)) {
                    setLastNameError('Last name is required.');
                } else if (!isEncoded(props.client.clientLastName)) {
                    setLastNameError('Last name has invalid characters.')
                } else {
                    setLastNameError('');
                }
            }

            if (props.client.clientType === CLIENT_TYPE_ORG_ID && stringEmpty(props.client.orgName)) {
                setOrgNameError('Organization name is required.');
            } else if (props.client.clientType === CLIENT_TYPE_ORG_ID && !stringEmpty(props.client.orgName)
                && !isEncoded(props.client.orgName)) {
                setOrgNameError('Organization name has invalid characters.')
            } else {
                setOrgNameError('')
            }
        }
    }, [props.showAllErrors,
        props.client.clientFirstName,
        props.client.clientMiddleName,
        props.client.clientLastName,
        props.client.clientType,
        props.client.orgName]);
    return (
        <>
            <div className="row util-padding-top-20">
                <InlineRadio
                    id={props.id + ".clientType"}
                    options={[{id: "1", text: "Person"}, {id: "2", text: "Organization"}]}
                    defaultValue={props.client.clientType}
                    onChange={setClientType}
                    disabled={editable}
                />
            </div>
            <div className="row util-padding-bottom-20">
                {props.client.clientType === CLIENT_TYPE_PERSON_ID &&

                <div className="form-group">
                    <InputBox
                        id={props.id + '.clientFirstName'}
                        label='First name'
                        length='3'
                        required={true}
                        error={firstNameError}
                        value={props.client.clientFirstName}
                        onChange={setClientFirstName}
                        readonly={editable}
                        maxLength={50}
                    />
                    <InputBox
                        id={props.id + '.clientMiddleName'}
                        label='Middle name'
                        length='3'
                        error={middleNameError}
                        value={props.client.clientMiddleName}
                        onChange={setClientMiddleName}
                        readonly={editable}
                        maxLength={50}
                    />
                    <InputBox
                        id={props.id + '.clientLastName'}
                        label='Last name'
                        length='3'
                        required={true}
                        error={lastNameError}
                        value={props.client.clientLastName}
                        onChange={setClientLastName}
                        readonly={editable}
                        maxLength={50}
                    />
                </div>
                }
                {props.client.clientType === CLIENT_TYPE_ORG_ID &&
                <div className="form-group">
                    <InputBox
                        id={props.id + '.orgName'}
                        label='Organization name'
                        length='9'
                        required={true}
                        error={orgNameError}
                        value={props.client.orgName}
                        onChange={setClientOrgName}
                        readonly={editable}
                        maxLength={50}
                    />
                </div>
                }
                {props.showRemoveClient && !editable &&
                <div className='col-md-1 util-padding-top-10'>
                    <Button text='Remove' color='red' onClick={props.removeClient}/>
                </div>
                }
                {props.showAddAnotherClient && !editable &&
                <div className='util-padding-top-10 pull-right'>
                    <Button text='Add another client' onClick={props.addAnotherClient}/>
                </div>
                }
            </div>
        </>
    )
}

ClientInformation.propTypes = {
    id: PropTypes.string,
    showAddAnotherClient: PropTypes.bool,
    showRemoveClient: PropTypes.bool,
    addAnotherClient: PropTypes.func,
    removeClient: PropTypes.func,
    client: PropTypes.object,
    setClient: PropTypes.func
}