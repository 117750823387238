import axios from "axios";
import {DOCUMENT_TYPE_CHECK, DOCUMENT_TYPE_STOCK} from "./constants";
import {convertDepositAmount, convertDocumentInformation} from "./utils";

function determineBaseUrl() {
    const currentHostname = window.location.hostname;
    if(currentHostname.endsWith("localhost")) {
        return "http://localhost:8080/api";
    } else if(currentHostname.includes("staging")) {
        return "https://psicheckblotter.staging.principal.com/prod/api";
    } else {
        return "https://psicheckblotter.principal.com/prod/api";
    }
}

export function configureAxios(user) {
    const token = user === null ? '' : user.access_token;

    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    axios.defaults.baseURL = determineBaseUrl();
}

function post(data, endpoint, onSuccess = () => {}, onFailure = () => {}) {
    axios.post(endpoint, data)
        .then(onSuccess)
        .catch(error => {
            try {
                if (error.response.data[0]){
                    onFailure(error.response.data)
                } else {
                    onFailure([{field: "none", error: "An error occurred. Please try again later. If the problem persists please contact an administrator."}])
                }
            } catch {
                onFailure([{field: "none", error: "An error occurred. Please try again later. If the problem persists please contact an administrator."}])
            }
        });
}

export function fetchDomains(dispatch) {
    axios.get("/domains")
        .then(response => dispatch({type: 'UPDATE_DOMAINS', data: response.data}));
}

export function saveDocument(documentType, form, onSuccess, onFailure) {
    let docInfo = convertDocumentInformation(form.documentInformation, documentType)
    let docObj = documentType === DOCUMENT_TYPE_CHECK ? {"checkInformation":docInfo} : {"stockCertificateInformation":docInfo}
    let productFundings = convertDepositAmount(form.productFundings, documentType)
    post({...form, ...docObj, productFundings}, "/save-document", onSuccess, onFailure);
}

export function editDocument(documentType, form, onSuccess, onFailure) {
    let docInfo = convertDocumentInformation(form.documentInformation, documentType)
    let docObj = documentType === DOCUMENT_TYPE_CHECK ? {"checkInformation":docInfo} : {"stockCertificateInformation":docInfo}
    let productFundings = convertDepositAmount(form.productFundings, documentType)
    post({...form, ...docObj, productFundings}, "/edit-document", onSuccess, onFailure);
}
export function validateFinancialProfessional(fp, onSuccess, onFailure) {
    post(fp, "/validate-financial-professional", onSuccess, onFailure);
}

export function validateDocumentInformation(documentType, documentInformation, onSuccess, onFailure) {
    let docInfo = convertDocumentInformation(documentInformation, documentType)
    if (documentType === DOCUMENT_TYPE_STOCK) {
        post(docInfo,
            "/validate-stock-information", onSuccess, onFailure);
    } else {
        post(docInfo,
            "/validate-check-information", onSuccess, onFailure);
    }
}

export function validateWorkflows(workflows, onSuccess, onFailure) {
    post(workflows, "/validate-workflows", onSuccess, onFailure);
}

export function validateProductFundings(productFundings, onSuccess, onFailure) {
    post(productFundings, "/validate-product-fundings", onSuccess, onFailure);
}

export function getDocument(documentId, onSuccess, onFailure) {
    post(documentId, "/document-by-id", onSuccess, onFailure);
}

export function search(searchCriteria, onSuccess, onFailure){
    //todo add lodash

    post(searchCriteria, "/search", onSuccess, onFailure);
}

export function searchFinancialProfessionals(searchCriteria, onSuccess, onFailure) {
    post(searchCriteria, "/search-financial-professionals", onSuccess, onFailure)
}
