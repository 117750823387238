import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {useSelector} from "react-redux";
import Select from "../form/Select";
import InputBox from "../form/InputBox";
import {
    businessDaysBetween,
    getNewDateInLocalTimezone,
    isEncoded,
    stringEmpty,
    subtractDaysFromDate
} from "../../data/utils";
import DateInputBox from "../form/DateInputBox";
import {
    _529_BROKERAGE_ID,
    DESKTOP_DEPOSIT_ID,
    DESTINATION_CUSTODIAN_ID, DOCUMENT_TYPE_CHECK,
    DOCUMENT_TYPE_STOCK,
    FIELD_OFFICE_ID,
    FINANCIAL_PLANNING_ID,
    GENERAL_BROKERAGE_ID,
    HOME_OFFICE_ID,
    MAILED_TO_FIDELITY_ID,
    PRINCIPAL_ANNUITY_ID,
    PSI_ADVISORY_ID,
    REMOTE_DEPOSIT_ID,
    RETURNED_TO_SENDER_ID
} from "../../data/constants"

const Workflow = forwardRef((props, ref) => {

    const destinations = useSelector(state => state.domains.destinations || [])
    const locations = useSelector(state => state.domains.locations || [])
    const documentType = useSelector(state => state.form.documentType);
    const documentDate = useSelector(state => state.match.documentDate);
    const productTypes = useSelector(state => state.match.productTypes);

    const {
        documentReceivedLocation,
        documentReceivedDate,
        documentDestination,
        documentSentDate,
        custodianName,
        lateEntryReason,
        returnedToSenderReason
    } = props.workflow;

    const [showAllErrors, setShowAllErrors] = useState(false);
    const editable = props.editable === undefined ? false : !props.editable;
    const index = props.index

    const setDocumentReceivedLocation = (documentReceivedLocation) => {
        props.setWorkflow(index, {...props.workflow, documentReceivedLocation})
    }
    const setDocumentDestination = (documentDestination) => {
        props.setWorkflow(index, {...props.workflow, documentDestination})
    }
    const setDocumentReceivedDate = (documentReceivedDate) => {
        props.setWorkflow(index, {...props.workflow, documentReceivedDate})
    }
    const setDocumentSentDate = (documentSentDate) => {
        props.setWorkflow(index, {...props.workflow, documentSentDate})
    }
    const setCustodianName = (custodianName) => {
        props.setWorkflow(index, {...props.workflow, custodianName})
    }
    const setLateEntryReason = (lateEntryReason) => {
        props.setWorkflow(index, {...props.workflow, lateEntryReason})
    }
    const setReturnedToSenderReason = (returnedToSenderReason) => {
        props.setWorkflow(index, {...props.workflow, returnedToSenderReason})
    }

    function productTypesAllowRemoteDeposit(productTypes) {
        return productTypes && productTypes.every(productType => {
            return [GENERAL_BROKERAGE_ID, _529_BROKERAGE_ID, PSI_ADVISORY_ID].includes(productType)
        });
    }

    function productTypesAllowDesktopDeposit(productTypes) {
        return productTypes && productTypes.every(productType => {
            return [PRINCIPAL_ANNUITY_ID, FINANCIAL_PLANNING_ID].includes(productType)
        });
    }

    const defaultStockDestinations = () => {
        return destinations.filter((destination) => [MAILED_TO_FIDELITY_ID, RETURNED_TO_SENDER_ID].includes(destination.id))
    }

    const defaultCheckDestinations = () => {
        return destinations.filter(destination => ![MAILED_TO_FIDELITY_ID].includes(destination.id))
    }

    const filteredDestinations = () => {
        if (documentType === DOCUMENT_TYPE_STOCK) {
            return defaultStockDestinations()
        }
        let checkDestinations = defaultCheckDestinations()
        if (documentDestination !== REMOTE_DEPOSIT_ID && !productTypesAllowRemoteDeposit(productTypes)) {
            checkDestinations = checkDestinations.filter(destination => destination.id !== REMOTE_DEPOSIT_ID)
        }
        if (documentDestination !== DESKTOP_DEPOSIT_ID && !productTypesAllowDesktopDeposit(productTypes)) {
            checkDestinations = checkDestinations.filter(destination => destination.id !== DESKTOP_DEPOSIT_ID)
        }
        return checkDestinations
    }

    const lateEntryReasonRequired = () => {
        const dateReceived = getNewDateInLocalTimezone(documentReceivedDate);
        const dateSent = getNewDateInLocalTimezone(documentSentDate);
        if (!stringEmpty(documentSentDate)) {
            //if field office && sent more than a day after it was received
            if (documentReceivedLocation === FIELD_OFFICE_ID && dateReceived && dateSent &&
                (businessDaysBetween(dateReceived, dateSent) > 1)) {
                return true;
            }

            //if home office && received more than a week before it was sent
            if (documentReceivedLocation === HOME_OFFICE_ID && dateReceived && dateSent &&
                (businessDaysBetween(dateReceived, dateSent) > 7)) {
                return true;
            }
        }

        if (!stringEmpty(documentReceivedDate)) {
            // Check Date is greater than 14 days before received date.
            if (documentType === DOCUMENT_TYPE_CHECK &&
                documentDate &&
                index < 1 && //Only first workflow uses this rule
                getNewDateInLocalTimezone(documentDate) < subtractDaysFromDate(dateReceived, 14)) {
                return true;
            }
            return props.lateEntryReasonRequired && props.lateEntryReasonRequired(dateReceived, index);
        }
        return false;
    }

    const returnedToSenderReasonRequired = () => {
        return (documentDestination && documentDestination === RETURNED_TO_SENDER_ID);
    }

    useEffect(() => {
        if (documentDestination !== DESTINATION_CUSTODIAN_ID) {
            setCustodianName('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentDestination]);


    // Invalid functions return either true or truthy (error string) if the field is invalid
    const documentReceivedDateInvalid = () => {
        if (getNewDateInLocalTimezone(documentReceivedDate) > new Date()) {
            return `Received date cannot be in the future.`;
        } else if (stringEmpty(documentReceivedDate)) {
            return showAllErrors ? `Received date is required.` : true;
        } else {
            return props.receivedDateInvalid && props.receivedDateInvalid(documentReceivedDate, index)
        }
    }

    const documentSentDateInvalid = () => {
        if (props.sentDateInvalid && props.sentDateInvalid(documentSentDate, index)) {
            return props.sentDateInvalid(documentSentDate, index)
        } else if (new Date(documentSentDate) < new Date(documentReceivedDate)) {
            return `Sent date cannot be before received date.`;
        } else if (getNewDateInLocalTimezone(documentSentDate) > new Date()) {
            return `Sent date cannot be in the future.`;
        } else if (documentReceivedLocation === FIELD_OFFICE_ID && stringEmpty(documentSentDate)) {
            return showAllErrors ? `Sent date is required.` : true;
        } else if (stringEmpty(documentSentDate) && !stringEmpty(documentDestination)) {
            return showAllErrors ? `Sent date is required` : true;
        } else {
            return false
        }
    }

    const documentReceivedLocationInvalid = () => {
        if (stringEmpty(documentReceivedLocation)) {
            return showAllErrors ? `Received location is required.` : true;
        } else {
            return false;
        }
    }

    const documentDestinationInvalid = () => {
        if (props.destinationInvalid && props.destinationInvalid(documentDestination, index)) {
            return props.destinationInvalid(documentDestination, index)
        } else if (documentDestination === REMOTE_DEPOSIT_ID && !productTypesAllowRemoteDeposit(productTypes)) {
            return 'Remote deposit is not a valid destination for those product types.'
        } else if (documentDestination === DESKTOP_DEPOSIT_ID && !productTypesAllowDesktopDeposit(productTypes)) {
            return 'Desktop deposit is not a valid destination for those product types.'
        } else if (documentReceivedLocation === FIELD_OFFICE_ID && stringEmpty(documentDestination)) {
            return showAllErrors ? `Sent to is required.` : true;
        } else if (stringEmpty(documentDestination) && !stringEmpty(documentSentDate)) {
            return showAllErrors ? `Sent to is required` : true;
        } else {
            return false;
        }
    }

    const lateEntryReasonInvalid = () => {
        if (lateEntryReasonRequired()) {
            if (stringEmpty(lateEntryReason)) {
                return showAllErrors ? `Late entry reason is required` : true;
            } else if (!isEncoded(lateEntryReason) && !stringEmpty(lateEntryReason)) {
                return showAllErrors ? `Late entry reason has invalid characters.` : true;
            } else {
                return false;
            }
        } else if (!lateEntryReasonRequired()) {
            if (lateEntryReason != null && !isEncoded(lateEntryReason) && !stringEmpty(lateEntryReason)) {
                return showAllErrors ? `Late entry reason has invalid characters.` : true;
            } else {
                return false;
            }
        }
    }

    const returnedToSenderReasonInvalid = () => {
        if (returnedToSenderReasonRequired() && stringEmpty(returnedToSenderReason)) {
            return showAllErrors ? `Returned to sender reason is required` : true;
        } else if (returnedToSenderReasonRequired() && !isEncoded(returnedToSenderReason) && !stringEmpty(returnedToSenderReason)) {
            return showAllErrors ? `Returned to sender reason has invalid characters.` : true;
        } else {
            return false;
        }
    }

    const custodianNameInvalid = () => {
        if (!stringEmpty(custodianName) && !isEncoded(custodianName)) {
            return showAllErrors ? `Custodian name has invalid characters.` : true;
        } else {
            return false;
        }
    }

    // Error and validation checking
    const workflowValid = () => {
        setShowAllErrors(true);
        return !(!!documentReceivedDateInvalid() ||
            !!documentSentDateInvalid() ||
            !!documentReceivedLocationInvalid() ||
            !!documentDestinationInvalid() ||
            !!lateEntryReasonInvalid() ||
            !!returnedToSenderReasonInvalid() ||
            !!custodianNameInvalid()
        );
    }

    useImperativeHandle(ref, () => ({
        isValid: workflowValid
    }))

    return (
        <>
            <div className="row util-padding-top-10">
                <div className="form-group">
                    <Select
                        id={'documentReceivedLocation'}
                        items={locations}
                        label="Received location"
                        required={true}
                        length={editable ? "3" : "6"}
                        value={documentReceivedLocation}
                        onChange={setDocumentReceivedLocation}
                        disabled={editable}
                        error={documentReceivedLocationInvalid()}
                    />
                    <DateInputBox
                        id='documentReceivedDate'
                        label="Received date"
                        required={true}
                        length={editable ? "3" : "6"}
                        value={documentReceivedDate}
                        onChange={setDocumentReceivedDate}
                        readonly={editable}
                        error={documentReceivedDateInvalid()}
                    />
                    <Select
                        id='documentDestination'
                        label="Sent to"
                        disabled={editable}
                        items={filteredDestinations()}
                        length={editable ? "3" : "6"}
                        required={documentReceivedLocation === FIELD_OFFICE_ID}
                        value={documentDestination}
                        onChange={setDocumentDestination}
                        error={documentDestinationInvalid()}
                    />
                    <DateInputBox
                        id='documentSentDate'
                        label="Sent date"
                        readonly={editable}
                        length={editable ? "3" : "6"}
                        required={documentReceivedLocation === FIELD_OFFICE_ID}
                        value={documentSentDate}
                        onChange={setDocumentSentDate}
                        error={documentSentDateInvalid()}
                    />
                    {(documentDestination && documentDestination === '1') &&
                    <InputBox
                        id='custodianName'
                        label='Custodian name'
                        length={"12"}
                        value={custodianName}
                        onChange={setCustodianName}
                        error={custodianNameInvalid()}
                        readonly={editable}
                    />
                    }
                    {returnedToSenderReasonRequired() &&
                    <InputBox
                        id='returnedToSenderReason'
                        label='Returned to sender reason'
                        length={'12'}
                        required={returnedToSenderReasonRequired()}
                        value={returnedToSenderReason}
                        onChange={setReturnedToSenderReason}
                        error={returnedToSenderReasonInvalid()}
                        readonly={editable}
                    />
                    }
                    <InputBox
                        id='lateEntryReason'
                        label='Late entry reason'
                        length={'12'}
                        required={lateEntryReasonRequired()}
                        value={lateEntryReason}
                        onChange={setLateEntryReason}
                        error={lateEntryReasonInvalid()}
                        readonly={editable}
                    />
                </div>
            </div>
        </>
    );
})

export default Workflow;
