import {useUser} from "@principal-aws-oidc-auth/react";
import React from "react";
import jwt_decode from "jwt-decode";

export default function Logout() {
    const tenantId = window.location.hostname === 'psicheckblotter.principal.com' ? '3bea478c-1684-4a8c-8e85-045ec54ba430' : '50abac53-da5d-44dc-8586-587ec2d3d097';
    const user = useUser();
    const decoded = jwt_decode(user.access_token)

    function logoutUrl() {
        window.location.href = `https://login.microsoftonline.com/${tenantId}/oauth2/logout?sid=${user.session_state}&iss=${decoded.iss}`;
    }

    setTimeout(logoutUrl, 100);
    sessionStorage.clear();


    return (
        <h1>Logging out...</h1>
    )


}
