import React, {useEffect, useState} from "react";
import Button from "../form/Button";
import Select from "../form/Select";
import {
    convertDepositAmount,
    convertDocumentInformation,
    formatDocumentTotal,
    getDocumentText
} from "../../data/utils";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {DOCUMENT_TYPE_CHECK, DOCUMENT_TYPE_STOCK, URL_PARAM_CHECK, URL_PARAM_STOCK} from "../../data/constants";
import {TablePagination} from "../form/TablePagination";

export default function SearchResults(props) {

    const [currentResultsPerPage, setCurrentResultsPerPage] = useState("10");
    const [currentSearchPage, setCurrentSearchPage] = useState(1)
    const resultsPerPage = [{id: "10", text: "10"}, {id: "25", text: "25"}, {id: "50", text: "50"}, {
        id: "100",
        text: "100"
    }];
    const [showPagination, setShowPagination] = useState(undefined)
    const [rows, setRows] = useState([]);
    const [sortState, setSortState] = useState({'columnName': "", 'sortDirection': ""})
    const [pageRows, setPageRows] = useState([]);
    const [documentType, setDocumentType] = useState('');
    const documentText = useSelector(state => getDocumentText(documentType === DOCUMENT_TYPE_CHECK ? "check" : "stockcertificate"))

    const _ = require("lodash");

    useEffect(() => {
        setShowPagination(props.results.length > Number(currentResultsPerPage));
        setRows(props.results.map((row) => {
            let documentInfo = convertDocumentInformation(row.stockCertificateInformation || row.checkInformation)
            let documentTotal = documentInfo.documentTotal
            let productFundings = convertDepositAmount(row.productFundings)
            documentTotal = formatDocumentTotal(productFundings, documentTotal, props.documentTypeResults)
            documentInfo = {...documentInfo, documentTotal}
            let docObj = getDocumentText(props.documentTypeResults,
                {checkInformation:convertDocumentInformation( documentInfo,props.documentTypeResults )},
                {stockCertificateInformation:convertDocumentInformation( documentInfo,props.documentTypeResults )})
            return {...row, ...docObj, productFundings}
        }))
        setDocumentType(props.documentTypeResults)
        populatePage()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.results])

    useEffect(() => {
        if (sortState.columnName) {
            performSort()
            setCurrentSearchPage(1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortState])

    useEffect(() => {
        populatePage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rows, currentSearchPage, currentResultsPerPage])

    function populatePage() {
        const firstIndex = ((currentSearchPage - 1) * currentResultsPerPage);
        const secondIndex = (currentSearchPage * currentResultsPerPage) > props.results.length ? undefined : currentSearchPage * currentResultsPerPage;
        setPageRows(rows.slice(firstIndex, secondIndex));
    }

    function getTotalPageCount() {
        return Math.ceil(props.results.length / currentResultsPerPage)
    }

    function getClientNames(productFundings) {
        return productFundings.map(pf => pf.product)
            .flatMap(product => product.clients)
            .map(client => extractNameFromClient(client));
    }

    function extractNameFromClient(client) {
        if (client.clientFirstName) {
            const names = [client.clientFirstName, client.clientMiddleName, client.clientLastName]
            return names.join(' ');
        } else {
            return client.orgName;
        }
    }

    function sortByColumn(columnName) {
        if (columnName === sortState.columnName && sortState.sortDirection === "up") {
            setSortState({'columnName': columnName, 'sortDirection': 'down'})
        } else {
            setSortState({'columnName': columnName, 'sortDirection': "up"})
        }
    }

    function performSort() {
        if (sortState.sortDirection === 'down') {
            setRows([...rows].reverse());
        } else {
            if (sortState.columnName === "checkInformation.checkAmount") {
                setRows(_.sortBy(props.results, [function (o) {
                    return Number(o.checkInformation.checkAmount)
                }]));
            } else if (sortState.columnName === "stockCertificateInformation.shareTotal") {
                    setRows(_.sortBy(props.results, [function (o) {
                        return Number(o.stockCertificateInformation.shareTotal)
                    }]));
            } else if (sortState.columnName === "checkInformation.checkPayee") {
                setRows(_.sortBy(props.results, [function (o) {
                    return o.checkInformation.checkPayee.toUpperCase()
                }]));
            } else if (sortState.columnName === "stockCertificateInformation.companyName") {
                setRows(_.sortBy(props.results, [function (o) {
                    return o.stockCertificateInformation.companyName.toUpperCase()
                }]));
            } else {
                if (documentType === DOCUMENT_TYPE_CHECK) {
                    setRows(_.sortBy(props.results, [function (o) {
                        return Number(o.checkInformation.checkAmount)
                    }]));
                } else {
                    setRows(_.sortBy(props.results, [function (o) {
                        return Number(o.stockCertificateInformation.shareTotal)
                    }]));
                }
            }
        }
    }

    function getShownNumber() {
        if (showPagination) {
            const startRow = (currentSearchPage - 1) * currentResultsPerPage + 1;
            const lastRow = (currentSearchPage * currentResultsPerPage) > props.results.length ? props.results.length : currentSearchPage * currentResultsPerPage;
            return `${startRow} to ${lastRow}`
        }
        return props.results.length;
    }

    function getSortClass(columnName) {
        return columnName === sortState.columnName ? `fa fa-sort-${sortState.sortDirection} text-muted` : "fa fa-sort text-disabled";
    }

    const SortButton = ({columnName}) => {
        return (
            <>
                <th className="text-right">
                    <button aria-label="Sort column"
                            onClick={() => sortByColumn(columnName)}
                            className={getSortClass(columnName)}
                    />
                </th>
            </>
        )
    }

    return (
        <>
            <div className="util-padding-top-30">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="panel panel-default">
                            <div className="panel-heading">
                                <span className="panel-title">Search results</span>
                                {props.access.EDIT_DOCUMENT &&
                                <a href={ `/addnew/${documentType === DOCUMENT_TYPE_STOCK ? URL_PARAM_STOCK : URL_PARAM_CHECK}` }><Button className='btn-sm pull-right' text='Add New' color='default'/></a>
                                }
                            </div>
                            <div className="panel-body">
                                <span className="form-inline">
                                <Select
                                    id="resultsPerPage"
                                    label="Results per page"
                                    length={4}
                                    items={resultsPerPage}
                                    value={currentResultsPerPage}
                                    defaultValue={currentResultsPerPage}
                                    onChange={x => {
                                        setCurrentResultsPerPage(x);
                                        setShowPagination(props.results.length > Number(x));
                                        setCurrentSearchPage(1)
                                    }}
                                    className="input-sm"/>
                                </span>
                            </div>
                            {props.results.length === 0 &&
                            <div className="panel-body text-center">
                                No results were found. Refine your search.
                            </div>
                            }
                            {props.results.length > 1000 &&
                            <div className="panel-body text-center">
                                Your search returned <strong>{props.results.length} </strong> records. Enter
                                additional information and search again.
                            </div>
                            }
                            {props.results.length > 0 &&
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th className="text-nowrap">{`${documentText} number`}</th>

                                        <th className="text-nowrap">{`${documentText} date`}</th>
                                        <SortButton columnName={"documentDate"}/>

                                        {documentType === DOCUMENT_TYPE_CHECK &&
                                        <>
                                            <th>Payee name</th>
                                            <SortButton columnName={"checkInformation.checkPayee"}/>

                                            <th>Check amount</th>
                                            <SortButton columnName={"checkInformation.checkAmount"}/>
                                        </>
                                        }
                                        {documentType === DOCUMENT_TYPE_STOCK &&
                                        <>
                                            <th>Company name</th>
                                            <SortButton columnName={"stockCertificateInformation.companyName"}/>

                                            <th>Share total</th>
                                            <SortButton columnName={"stockCertificateInformation.shareTotal"}/>
                                        </>
                                        }

                                        <th className="text-nowrap">Client name(s)</th>
                                        <th/>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {pageRows.map((document) => {
                                        return <tr key={document.documentId}>
                                            <td className="text-nowrap">{document.documentNumber} </td>
                                            <td colSpan="2">{document.documentDate} </td>
                                            {documentType === DOCUMENT_TYPE_CHECK &&
                                            <>
                                                <td colSpan="2">{document.checkInformation.checkPayee} </td>
                                                <td colSpan="2">{document.checkInformation.checkAmount} </td>
                                            </>
                                            }
                                            {documentType === DOCUMENT_TYPE_STOCK &&
                                            <>
                                                <td colSpan="2">{document.stockCertificateInformation.companyName} </td>
                                                <td colSpan="2">{document.stockCertificateInformation.shareTotal} </td>
                                            </>
                                            }
                                            <td>{getClientNames(document.productFundings).map((name, index) =>
                                                <span key={document.documentId + (index * 100000)}>
                                                    {name} <br/>
                                                    </span>
                                            )} </td>
                                            <td className="text-right">
                                                <Link to={`/document/${document.documentId}`}><Button
                                                    text='Select'/></Link>
                                            </td>
                                        </tr>
                                    })}
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <td colSpan={showPagination ? "4" : "9"}>Showing {getShownNumber()} of {props.results.length} {`${getDocumentText(documentType, "checks", "stock certificates")}`}</td>
                                        {showPagination &&
                                            <td colSpan="5" className="text-right">
                                            <div className={"pull-right"}>
                                                <TablePagination
                                                    numberOfPages={getTotalPageCount()}
                                                    currentPage={currentSearchPage}
                                                    setCurrentPage={setCurrentSearchPage}
                                                />
                                            </div>
                                            </td>
                                        }
                                    </tr>
                                    </tfoot>
                                </table>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
