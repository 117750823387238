import React from "react";
import ErrorWrapper from "./ErrorWrapper";
import {PropTypes} from "prop-types";

export default function InputBox(props){

    const length = props.length ? props.length : "6";
    const maxLength = props.maxLength ? props.maxLength : 255;

    const validate = (value) => {
        if (props.integerOnly) {
            // Regex removes all input that is not a number,
            // a single decimal point, or more than two numbers after the decimal
            value = value.replace(/[^0-9]/g, '')
        }
        return value;
    }
    return (
        <div className={"col-md-" + length}>
            <ErrorWrapper error={props.error}>
                <label htmlFor={props.id} className={props.required ? 'is-required' : '' }>{props.label}</label>
                <input type={props.type ? props.type : "text"}
                       id={props.id}
                       className="form-control"
                       value={props.value || ''}
                       onChange={(x) => {props.onChange(validate(x.target.value))}}
                       disabled={!!props.disabled}
                       readOnly={!!props.readonly}
                       maxLength={maxLength}
                       pattern={props.pattern}
                />
            </ErrorWrapper>
        </div>
    );

}

InputBox.propTypes = {
    length: PropTypes.string,
    id: PropTypes.string.isRequired,
    type: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    maxLength: PropTypes.number,
    pattern: PropTypes.string,
    integerOnly: PropTypes.bool
}