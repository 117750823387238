import React, {useState} from "react";
import {TablePagination} from "./TablePagination";

const DataTable = (props) => {
    let {
        title,
        id,
        columnHeaders,
        data
    } = props;

    const [currentPage, setCurrentPage] = useState(1)
    const [resultsPerPage] = useState(4)

    function filterCurrentPage(data) {
        return data.filter((row, idx) => {
            return (currentPage * resultsPerPage - resultsPerPage) <= idx && idx <= (currentPage * resultsPerPage)
        })
    }

    function mapTableBody(data) {
        return <tbody>
        {data.map((row) => {
            return (
                <tr>
                    {row.map((item) => <td className="pds-dataTable-table-item">{item}</td>)}
                </tr>
            )
        })}
        </tbody>;
    }

    return (
        <div className="pds-dataTable">
            <div className="pds-dataTable-header">
                <p aria-label="Title of the dataTable" className="pds-dataTable-title">{title}</p>
            </div>
            {columnHeaders && data &&
            <div className="pds-dataTable-table-container pds-table-scrolling">
                <table className="pds-table-bordered pds-table-bordered" id={`pds-dataTable-${id}`} aria-live="polite">
                    <thead>
                    <tr>
                        {columnHeaders.map((header) => {
                                return <th className="sort pds-dataTable-table-header" scope="col">{header}</th>
                            }
                        )}
                    </tr>
                    </thead>
                    {mapTableBody(filterCurrentPage(data))}
                </table>
            </div>
            }
            <div className={"pull-right"}>
                <TablePagination
                    numberOfPages={Math.ceil(data.length / resultsPerPage)}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />
            </div>
        </div>
    )
}

export default DataTable;