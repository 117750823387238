import {PropTypes} from 'prop-types';
import React from "react";

export default function InlineRadio(props) {

    const length = props.length ? props.length : "6";

    return (
        <div className={"col-md-" + length}>
            {props.options && props.options.map((option, index) =>
                <div key={option.id} className="radio styled-radio radio-inline">
                    <input
                        type="radio"
                        name={props.id}
                        id={props.id + option.id}
                        key={props.id + ':' + option.id + ':' + index}
                        value={option.id}
                        checked={option.id === props.defaultValue}
                        disabled={!!props.disabled}
                        onChange={event => props.onChange(event.target.value)}
                    />
                    <label htmlFor={props.id + option.id}>{option.text}</label>
                </div>
            )}
        </div>
    );
}

InlineRadio.propTypes = {
    length: PropTypes.string,
    id: PropTypes.string.isRequired,
    options: PropTypes.array,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    disabled: PropTypes.bool
}