import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {filter, find, join} from "lodash";
import {
    dateStringToMMddyyyy,
    formatDepositAmounts,
    formatDocumentTotal,
    getDocumentText,
    getFinancialProfessionalName,
    stringEmpty
} from "../../data/utils";
import Button from "../form/Button";
import {Link} from "react-router-dom";
import {actions} from "../../data/reducer";

export default function Summary(props) {

    const form = useSelector(state => state.form);
    const domains = useSelector(state => state.domains);
    const documentId = form.documentId;
    const fp = form.financialProfessional;

    const dispatch = useDispatch();
    const documentType = useSelector(state => state.form.documentType)
    const documentText = useSelector(state => getDocumentText(state.form.documentType))

    function getDomainText(domain, id) {
        return find(domain, ['id', String(id)]).text;
    }

    function getClientNames(clients) {
        return clients.map(client => {
            return join(filter([client.clientFirstName, client.clientMiddleName, client.clientLastName, client.orgName], str => !stringEmpty(str)), ' ');
        })
    }

    function addAnotherCheck() {
        props.setSelectedProfessional(false)
        actions.resetState(dispatch);
    }

    function SummaryHeader({text}) {
        return (
            <div className='row'>
                <div className='col-lg-12'>
                    <h3>{text}:</h3>
                    <hr role="presentation"/>
                </div>
            </div>
        )
    }

    function SummaryDetail({label, value, className}) {
        const divClass = className ? className : "col-lg-3"
        return (
            <>
                <div className={divClass}>
                    <h5>{label}:</h5>
                    <p>{value}</p>
                </div>
            </>
        )
    }

    return (
        <>
            <div className={"col-md-8 col-md-offset-2"}>
                <div className="alert alert-success alert-icon"
                     role="alert">The {getDocumentText(documentType, "check", "stock certificate")} was successfully
                    added
                </div>
            </div>

            <SummaryHeader text={'Financial professional'}/>
            <div className={'col-lg-12 col-offset-1 bg-light-blue util-padding-bottom-10'}>
                <div className='row'>
                    <SummaryDetail label={"Name"} value={getFinancialProfessionalName(form.financialProfessional)}/>
                    <SummaryDetail label={"CRD"} value={form.financialProfessional.fpCrdId}/>
                </div>
            </div>

            <SummaryHeader text={`${documentText} information`}/>
            <div className={'col-lg-12 col-offset-1 bg-light-blue util-padding-bottom-10'}>
                <div className='row'>
                    <SummaryDetail label={`${documentText} number`} value={form.documentNumber}/>
                    <SummaryDetail label={`${documentText} date`} value={dateStringToMMddyyyy(form.documentDate)}/>
                    <SummaryDetail label={getDocumentText(documentType, "Check amount", "Share total")}
                                   value={formatDocumentTotal(form.productFundings, form.documentInformation.documentTotal, documentType)}/>
                    <SummaryDetail label={getDocumentText(documentType, "Check payee", "Company name")}
                                   value={form.documentInformation.documentPayee}/>
                </div>
            </div>

            <SummaryHeader text={'Product information'}/>
            {fp && formatDepositAmounts(form.productFundings, form.documentInformation.documentTotal, documentType).map((productFunding, index) =>
                <div key={index}>
                    <div className={'col-lg-12 col-offset-1 bg-light-blue util-padding-bottom-10'}>
                        <div className='row'>
                            <SummaryDetail label={"Account number"}
                                           value={productFunding.product.accountNumber ? productFunding.product.accountNumber : 'No account number assigned.'}/>
                            <SummaryDetail label={'Product type'}
                                           value={getDomainText(domains.productTypes, productFunding.product.productType)}/>
                            <SummaryDetail label={`${getDocumentText(documentType, "Deposit amount", "Share amount")}`}
                                           value={productFunding.depositAmount}/>
                            <div className='col-lg-3'>
                                <h5>Client name(s):</h5>
                                {getClientNames(productFunding.product.clients).map((name, index) =>
                                    <p key={name + index}>
                                        {name}<br/>
                                    </p>
                                )}
                            </div>
                        </div>

                    </div>
                </div>
            )}


            <SummaryHeader text={`${documentText} history`}/>
            <div className={'col-lg-12 col-offset-1 bg-light-blue util-padding-bottom-10'}>
                <div className='row'>
                    <SummaryDetail label="Received location"
                                   value={getDomainText(domains.locations, form.workflows[0].documentReceivedLocation)}/>
                    <SummaryDetail label="Received date"
                                   value={dateStringToMMddyyyy(form.workflows[0].documentReceivedDate)}/>
                    {form.workflows[0].documentDestination &&
                    <SummaryDetail label="Sent to"
                                   value={getDomainText(domains.destinations, form.workflows[0].documentDestination)}/>
                    }
                    {form.workflows[0].documentSentDate &&
                    <SummaryDetail label="Sent date"
                                   value={dateStringToMMddyyyy(form.workflows[0].documentSentDate)}/>

                    }
                </div>
                {form.workflows[0].custodianName &&
                <div className='row'>
                    <SummaryDetail
                        label={'Custodian'}
                        value={form.workflows[0].custodianName}
                        className={"col util-padding-horz-15"}
                    />
                </div>
                }
                {form.workflows[0].lateEntryReason &&
                <div className={'row'}>
                    <SummaryDetail
                        label={'Late entry reason'}
                        value={form.workflows[0].lateEntryReason}
                        className={"col util-padding-horz-15"}
                    />
                </div>
                }
            </div>

            <div className="container row">
                <div className="pull-left util-padding-top-20">
                    <Link to={`/document/${documentId}`}><Button text='Edit'/></Link>
                </div>
                <div className="pull-right util-padding-top-20">
                    <Button text={`Add another ${getDocumentText(documentType, "check", "stock certificate")}`}
                            onClick={addAnotherCheck}/>
                </div>
            </div>

        </>
    );
}