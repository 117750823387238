import Icon from "@pds-react/icon";
import Pagination, {PaginationItem} from "@pds-react/pagination";
import React from "react";

export function TablePagination(props) {
    const {
        numberOfPages,
        currentPage,
        setCurrentPage
    } = props;

    return (
        <Pagination className="pds-pagination" aria-label="Page navigation example">
            <React.Fragment key=".0">
                <PaginationItem
                    key={`firstPage`}
                    className="pds-pagination-item"
                    aria-label={`go to first page`}
                    onClick={() => setCurrentPage(1)}
                    disabled={currentPage === 1}
                >
                    <Icon name="chevrons-left"/>
                </PaginationItem>
                <PaginationItem
                    key={"previousPage"}
                    className="pds-pagination-item"
                    aria-label="go to previous page"
                    onClick={() => currentPage !== 1 && setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    <Icon name="chevron-left"/>
                </PaginationItem>
                {currentPage > 3 &&
                    <PaginationItem
                        key={`firstPlaceholder`}
                        className="pds-pagination-item"
                        aria-label={`go back three pages`}
                        onClick={() => setCurrentPage(currentPage - 3)}
                    > ... </PaginationItem>
                }
                {[...Array(numberOfPages)].map((_, idx) => {
                    return (
                        <PaginationItem
                            key={`page${idx}`}
                            className="pds-pagination-item"
                            aria-label={`go to page ${idx}`}
                            onClick={() => setCurrentPage(idx + 1)}
                            active={idx+1 === currentPage}
                        >
                            {idx + 1}
                        </PaginationItem>
                    )
                    }).filter((_, idx) => {
                        if (currentPage < 4 && idx < 6){
                            return true
                        } else if (currentPage > numberOfPages - 4 && idx > numberOfPages - 7) {
                            return true
                        } else {
                            return idx < currentPage + 2 && idx > currentPage - 4
                        }
                })
                }
                {currentPage < numberOfPages - 3 &&
                <PaginationItem
                    key={`lastPlaceholder`}
                    className="pds-pagination-item"
                    aria-label={`go forward three pages`}
                    onClick={() => setCurrentPage(currentPage + 3)}
                > ... </PaginationItem>
                }
                <PaginationItem
                    key={"nextPage"}
                    className="pds-pagination-item"
                    variant="step-forward"
                    aria-label="go to next page"
                    onClick={() => currentPage !== numberOfPages && setCurrentPage(currentPage + 1)}
                    disabled={currentPage === numberOfPages}
                >
                    <Icon name="chevron-right"/>
                </PaginationItem>
                <PaginationItem
                    key={`lastPage`}
                    className="pds-pagination-item"
                    aria-label={`go to last page`}
                    onClick={() => setCurrentPage(numberOfPages)}
                    disabled={currentPage === numberOfPages}
                >
                    <Icon name="chevrons-right"/>
                </PaginationItem>

            </React.Fragment>
        </Pagination>
    )
}