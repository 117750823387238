import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {actions} from "../../data/reducer";
import Workflow from "./Workflow";
import Button from "../form/Button";
import {getNewDateInLocalTimezone, stringEmpty, subtractDaysFromDate} from "../../data/utils";
import {slice} from "lodash";

const Workflows = forwardRef((props, ref) => {
    let {editable, access, setAllowEdit} = props;
    const dispatch = useDispatch();
    const workflowsGlobal = useSelector(state => state.form.workflows);
    const [workflowRefs, setWorkflowRefs] = useState([]);
    const [workflows, setWorkflows] = useState([])

    // load info from state
    useEffect(() => {
        if (workflowsGlobal && workflowsGlobal.length > 0) {
            setWorkflows(workflowsGlobal)
        }
    }, [workflowsGlobal]);

    const workflowsValid = () => workflowRefs.every(wf => wf.isValid())

    const setSpecificWorkflow = (index, workflow) => {
        setWorkflows([...slice(workflows, 0, index), workflow, ...slice(workflows, index + 1)])
    }

    const receivedDateInvalid = (documentReceivedDate, workflowIndex) => {
        if (workflowIndex === 0) { return false }
        const documentSentDate = workflows[workflowIndex - 1].documentSentDate
        const receivedDate = getNewDateInLocalTimezone(documentReceivedDate).setHours(0,0,0,0);
        const previousSentDate = documentSentDate && getNewDateInLocalTimezone(documentSentDate).setHours(0,0,0,0);
        if (receivedDate < previousSentDate) {
            return "Received date cannot be before previous workflow's sent date"
        } else {
            return false
        }
    }

    const sentDateInvalid = (documentSentDate, workflowIndex) => {
        if (workflowIndex === workflows.length - 1) { return false }
        const nextWorkflow = workflows[workflowIndex + 1]
        const sentDate = getNewDateInLocalTimezone(documentSentDate).setHours(0,0,0,0);
        const nextReceivedDate = getNewDateInLocalTimezone(nextWorkflow.documentReceivedDate).setHours(0,0,0,0);
        if (sentDate > nextReceivedDate) {
            return "Sent date cannot be after next workflow's received date"
        } else if (stringEmpty(documentSentDate) && !stringEmpty(nextWorkflow.documentReceivedDate)) {
            return "Sent date is required"
        } else {
            return false
        }
    }

    const destinationInvalid = (documentDestination, workflowIndex) => {
        if (workflowIndex === workflows.length - 1) { return false}
        const nextWorkflow = workflows[workflowIndex + 1]
        if (stringEmpty(documentDestination) && !stringEmpty(nextWorkflow.documentReceivedLocation)) {
            return "Sent to is required"
        } else {
            return false
        }
    }

    const lateEntryReasonRequired = (dateReceived, workflowIndex) => {
        if (workflowIndex === 0) { return false }
        const previousWorkflow = workflows[workflowIndex - 1]
        if (getNewDateInLocalTimezone(previousWorkflow.documentSentDate) < subtractDaysFromDate(dateReceived, 14)){
            return "Received date cannot be more than 14 days from previous workflow's sent date"
        } else {
            return false
        }

    }
    const addWorkflow = () => {
        // Have to set to global because changing allowEdit will cause a rerender and local state is lost
        actions.saveWorkflows(dispatch, workflows)
        actions.addNewWorkflow(dispatch);
        setAllowEdit(true)
    }

    const removeWorkflow = (index) => {
        let wfs = workflows.filter((_, idx) => idx !== index)
        actions.saveWorkflows(dispatch, wfs)
        setWorkflows(wfs)

        setWorkflowRefs(workflowRefs.filter((_, idx) => idx !== index))
    }

    const nextPage = (event) => {
        event.preventDefault()
        if (workflowsValid()) {
            actions.workflowsNextPage(dispatch, workflows);
        }
    }

    const previousPage = (event) => {
        event.preventDefault()
        actions.workflowsPreviousPage(dispatch, workflows);
    }

    useImperativeHandle(ref, () => ({
        isValid: workflowsValid,
        nextPage: nextPage,
        previousPage: previousPage,
        getFields: () => workflows
    }))

    return (
        <>
            {workflows.map((wf, idx) => {
                return (
                    <div className="row util-padding-horz-20" key={wf + idx}>
                        <Workflow
                            editable={editable}
                            ref={(ref) => workflowRefs[idx] = ref}
                            workflow={wf}
                            setWorkflow={setSpecificWorkflow}
                            sentDateInvalid={sentDateInvalid}
                            receivedDateInvalid={receivedDateInvalid}
                            destinationInvalid={destinationInvalid}
                            lateEntryReasonRequired={lateEntryReasonRequired}
                            index={idx}
                        />
                        <div className="row util-padding-20">
                            {access.ADD_WORKFLOW && editable && wf.workflowId === undefined &&
                            <Button
                                text="Remove workflow"
                                color="red"
                                className="pull-right"
                                onClick={() => removeWorkflow(idx)}
                            />
                            }
                        </div>
                    </div>
                )
            })
            }
            {access.ADD_WORKFLOW &&
            workflows[workflows.length - 1] &&
            workflows[workflows.length - 1].workforceId &&
            workflowsGlobal[workflows.length - 1].documentSentDate &&
            <div className="row util-padding-horz-20">
                <div className={"pull-right util-padding-right-5"}>
                    <Button
                        text="Add workflow"
                        onClick={addWorkflow}
                    />
                </div>
            </div>
            }
        </>
    )
})

export default Workflows;