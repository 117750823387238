import {omit, set} from "lodash";
import {
    editDocument,
    saveDocument,
    validateDocumentInformation,
    validateFinancialProfessional,
    validateProductFundings
} from "./api";
import {convertDepositAmount} from "./utils";

export const initialState = {
    form: {workflows: [{}], documentInformation: {}, productFundings: [{product: {clients: [{}]}}]},
    match: {productTypes:[]},
    domains: [],
    errors: [],
    addNewCurrentPage: 0
};
export const ADD_NEW_PAGES = ['Financial professional',
    'Check information',
    'Products',
    'Check history',
    'Summary'];

export function reducer(state, action) {
    switch (action.type) {
        case 'UPDATE_VALUE':
            return {...state, form: {...set(state.form, action.field, action.value)}}
        case 'UPDATE_MATCH':
            return {...state, match: {...set(state['match'], action.field, action.value)}}
        case 'REMOVE_VALUE':
            return {...state, form: omit(state.form, action.field)}
        case 'UPDATE_DOMAINS':
            return {...state, domains: action.data}
        case 'SET_ERRORS':
            let errors = action.errors
            return {...state, errors: errors}
        case 'CLEAR_ERRORS':
            return {...state, errors: []}
        case 'ADD_NEW_NEXT_PAGE':
            return state.addNewCurrentPage < ADD_NEW_PAGES.length - 1 ?
                {...state, addNewCurrentPage: state.addNewCurrentPage + 1, errors: []} :
                state;
        case 'ADD_NEW_PREVIOUS_PAGE':
            return state.addNewCurrentPage > 0 ?
                {...state, addNewCurrentPage: state.addNewCurrentPage - 1, errors: []} :
                state;
        case 'ADD_NEW_JUMP_TO_PAGE':
            return action.newPage >= 0 && action.newPage < ADD_NEW_PAGES.length ?
                {...state, addNewCurrentPage: action.newPage} :
                state;
        case 'UPDATE_FP':
            return {...state, form: {...state.form, financialProfessional: action.financialProfessional}}
        case 'UPDATE_DOCUMENT_INFORMATION':
            return {
                ...state,
                form: {
                    ...state.form,
                    documentInformation: action.documentInformation,
                    documentDate: action.documentDate,
                    documentNumber: action.documentNumber
                }
            }
        case 'UPDATE_PRODUCT_FUNDINGS':
            return {...state, form: {...state.form, productFundings: action.productFundings}}
        case 'UPDATE_WORKFLOWS':
            let workflows = action.workflows.map((wf) => {
                return {...wf, workforceId: state.form.workforceId}
            })
            return {...state, form: {...state.form, workflows}}
        case 'ADD_NEW_WORKFLOW':
            return {
                ...state,
                form: {...state.form, workflows: [...state.form.workflows, {workforceId: state.form.workforceId}]}
            }
        case 'RESET_STATE':
            return {
                ...initialState,
                form: {...initialState.form, documentType: state.form.documentType, workflows: [{}], workforceId: state.form.workforceId},
                domains: state.domains
            };
        case 'UPDATE_WORKFORCE_ID':
            return {...state, form: {...state.form, workforceId: action.workforceId}};
        default:
            return state
    }
}

export const actions = {
    addNewPreviousPage: dispatch => dispatch({type: 'ADD_NEW_PREVIOUS_PAGE'}),
    removeField: (dispatch, field) => dispatch({type: 'REMOVE_VALUE', field: field}),
    updateValue: (dispatch, field, value) => dispatch({type: 'UPDATE_VALUE', field: field, value: value}),
    updateMatch: (dispatch, field, value) => dispatch({type: 'UPDATE_MATCH', field: field, value: value}),
    updateErrors: (dispatch, errors) => dispatch({type: 'SET_ERRORS', errors: errors}),
    clearErrors: (dispatch) => dispatch({type: 'CLEAR_ERRORS'}),
    updateWorkforceId: (dispatch, workforceId) => dispatch({type: 'UPDATE_WORKFORCE_ID', workforceId}),
    addNewWorkflow: dispatch => dispatch({type: 'ADD_NEW_WORKFLOW'}),
    removeWorkflow: (dispatch, index) =>
        dispatch((dispatch, getState) => {
            let workflows = getState().form.workflows;
            workflows = workflows.filter((_, idx) => idx !== index);
            dispatch({type: 'UPDATE_WORKFLOWS', workflows: workflows});
        }),
    saveWorkflow: (dispatch, index, workflow) =>
        dispatch((dispatch, getState) => {
            let workflows = getState().form.workflows;
            workflows[index] = {...workflow}
            dispatch({type: 'UPDATE_WORKFLOWS', workflows: workflows});
        }),
    saveWorkflows: (dispatch, workflows) => dispatch({type: 'UPDATE_WORKFLOWS', workflows}),
    resetState: dispatch => dispatch({type: 'RESET_STATE'}),
    saveFinancialProfessional: (dispatch, financialProfessional) => {
        dispatch((dispatch, getState) =>
            dispatch({
                type: 'UPDATE_FP',
                financialProfessional: {...financialProfessional, workforceId: getState().form.workforceId}
            })
        )
    },
    saveDocumentInformation: (dispatch, documentInformation, documentDate, documentNumber) => {
        dispatch((dispatch, getState) =>
            dispatch({
                type: 'UPDATE_DOCUMENT_INFORMATION',
                documentInformation: {...documentInformation, workforceId: getState().form.workforceId},
                documentDate: documentDate,
                documentNumber: documentNumber
            })
        );
    },
    saveProductFundings: (dispatch, productFundings) => {
        dispatch((dispatch, getState) => {
            const pfs = productFundings.map((funding) => {
                const clients = funding.product.clients.map((client) => {
                    return ({
                        ...client,
                        workforceId: getState().form.workforceId
                    })
                })
                return ({
                    ...funding,
                    product: {...funding.product, workforceId: getState().form.workforceId, clients},
                    workforceId: getState().form.workforceId
                })
            })
            dispatch({type: 'UPDATE_PRODUCT_FUNDINGS', productFundings: pfs});
        })
    },
    financialProfessionalNextPage: (dispatch, financialProfessional) => {
        dispatch((dispatch, getState) =>
            validateFinancialProfessional(financialProfessional,
                (data) => {
                    dispatch({type: 'CLEAR_ERRORS'});
                    dispatch({
                        type: 'UPDATE_FP',
                        financialProfessional: {...financialProfessional, workforceId: getState().form.workforceId}
                    })
                    dispatch({type: 'ADD_NEW_JUMP_TO_PAGE', newPage: 1})
                },
                (errors) => dispatch({type: 'SET_ERRORS', errors: errors}))
        );
    },
    documentInformationNextPage: (dispatch, documentInformation, documentDate, documentNumber) => {
        dispatch((dispatch, getState) => {
                validateDocumentInformation(getState().form.documentType,
                    documentInformation,
                    (data) => {
                        dispatch({type: 'CLEAR_ERRORS'});
                        dispatch({
                            type: 'UPDATE_DOCUMENT_INFORMATION',
                            documentInformation: {...documentInformation, workforceId: getState().form.workforceId},
                            documentDate: documentDate,
                            documentNumber: documentNumber
                        });
                        dispatch({type: 'ADD_NEW_JUMP_TO_PAGE', newPage: 2});
                    },
                    (errors) => dispatch({type: 'SET_ERRORS', errors: errors}))
            }
        );
    },
    documentInformationPreviousPage: (dispatch, documentInformation, documentDate, documentNumber) => {
        dispatch({type: 'CLEAR_ERRORS'});
        dispatch({
            type: 'UPDATE_DOCUMENT_INFORMATION',
            documentInformation: documentInformation,
            documentDate: documentDate,
            documentNumber: documentNumber
        });
        dispatch({type: 'ADD_NEW_JUMP_TO_PAGE', newPage: 0});
    },
    productFundingsNextPage: (dispatch, productFundings) => {
        dispatch((dispatch, getState) =>
            validateProductFundings(convertDepositAmount(productFundings, getState().form.documentType),
                (data) => {
                    dispatch({type: 'CLEAR_ERRORS'});
                    const pfs = productFundings.map((funding) => {
                        const clients = funding.product.clients.map((client) => {
                            return ({
                                ...client,
                                workforceId: getState().form.workforceId
                            })
                        })
                        return ({
                            ...funding,
                            product: {...funding.product, workforceId: getState().form.workforceId, clients},
                            workforceId: getState().form.workforceId
                        })
                    })
                    dispatch({
                        type: 'UPDATE_PRODUCT_FUNDINGS',
                        productFundings: pfs
                    });
                    dispatch({type: 'ADD_NEW_JUMP_TO_PAGE', newPage: 3});
                },
                (errors) => dispatch({type: 'SET_ERRORS', errors: errors}))
        );
    },
    productFundingsPreviousPage: (dispatch, productFundings) => {
        dispatch({type: 'CLEAR_ERRORS'});
        dispatch({type: 'UPDATE_PRODUCT_FUNDINGS', productFundings: productFundings});
        dispatch({type: 'ADD_NEW_JUMP_TO_PAGE', newPage: 1});
    },
    workflowsNextPage: (dispatch, workflows) => {
        dispatch({type: 'UPDATE_WORKFLOWS', workflows: workflows});
        dispatch((dispatch, getState) => {
                saveDocument(
                    getState().form.documentType,
                    {...getState().form, workforceId: getState().form.workforceId},
                    (data) => {
                        dispatch({type: 'CLEAR_ERRORS'});
                        dispatch({type: 'UPDATE_VALUE', field: 'documentId', value: data.data.documentId})
                        dispatch({type: 'ADD_NEW_JUMP_TO_PAGE', newPage: 4});
                    },
                    (errors) => dispatch({type: 'SET_ERRORS', errors: errors}))
            }
        );
    },
    workflowsPreviousPage: (dispatch, workflows) => {
        dispatch({type: 'CLEAR_ERRORS'});
        dispatch({type: 'UPDATE_WORKFLOWS', workflows: workflows});
        dispatch({type: 'ADD_NEW_JUMP_TO_PAGE', newPage: 2});
    },
    editDocument: (dispatch, form, onSuccess, onFailure) => {
        dispatch({type: 'CLEAR_ERRORS'});
        dispatch((dispatch, getState) =>
            editDocument(
                getState().form.documentType,
                {...form, workforceId: getState().form.workforceId}, (data) => {
                    onSuccess(data)
                },
                (errors) => {
                    dispatch({type: 'SET_ERRORS', errors: errors})
                    onFailure(errors)
                }
            ))
    }
}
