import React from "react";
import {useSelector} from "react-redux";

export default function ErrorDisplay() {

    const errors = useSelector(state => state.errors);

    return (
        <>
            {errors.length > 0 && errors.map((error, index) =>
            <div className="row" key={error.field + index}>
                <div className="col-md-offset-2 col-md-8">
                    <div className="alert alert-danger alert-icon" role="alert">{error.error}</div>
                </div>
            </div>
            )}
        </>
    )
}