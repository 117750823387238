import {PropTypes} from "prop-types";
import React from "react";


export default function Button(props) {

    function getColorClassName(color) {
        switch (color) {
            case 'red':
                return "btn btn-danger";
            case 'default':
                return "btn btn-default";
            default:
                return "btn btn-primary"
        }
    }

    function getClassName() {
        if (props.className) {
            return props.className + ' ' + getColorClassName(props.color)
        } else {
            return getColorClassName(props.color);
        }
    }

    return (
        <button
            type={props.type ? props.type : "button"}
            className={getClassName()}
            onClick={props.onClick}
            disabled={props.disabled || props.loading}
        >
            <>
                {props.children}
                {props.loading &&
                <i className="fa fa-spinner fa-spin"/>
                }
                {" " + props.text}
            </>
        </button>
    );
}

Button.propTypes = {
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    color: PropTypes.string,
    className: PropTypes.string
}
