import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import InputBox from "../form/InputBox";
import {useDispatch, useSelector} from "react-redux";
import {actions} from "../../data/reducer";
import {stringEmpty} from "../../data/utils";

const FinancialProfessional = forwardRef((props, ref) => {

    const fpGlobal = useSelector(state => state.form.financialProfessional);
    const workforceId = useSelector(state => state.form.workforceId);

    const [fpFirstName, setFpFirstName] = useState('');
    const [fpMiddleName, setFpMiddleName] = useState('');
    const [fpLastName, setFpLastName] = useState('');
    const [fpCrdId, setFpCrdId] = useState('');

    const dispatch = useDispatch();

    const [showAllErrors, setShowAllErrors] = useState(false);

    const editable = props.editable === undefined ? false : !props.editable;

    function fpValid() {
        setShowAllErrors(true)
        return !(!!firstNameInvalid() ||
            !!lastNameInvalid())
    }

    const firstNameInvalid = () => {
        if (stringEmpty(fpFirstName)) {
            return showAllErrors ? "First name is required." : true;
        } else {
            return false;
        }
    }
    const lastNameInvalid = () => {
        if (stringEmpty(fpLastName)) {
            return showAllErrors ? "Last name is required." : true;
        } else {
            return false;
        }
    }

    useEffect(() => {
        if (fpGlobal) {
            setFpFirstName(fpGlobal.fpFirstName);
            setFpMiddleName(fpGlobal.fpMiddleName);
            setFpLastName(fpGlobal.fpLastName);
            setFpCrdId(fpGlobal.fpCrdId);
        }
    }, [fpGlobal]);

    function nextPage(event) {
        event.preventDefault();
        if (fpValid()) {
            actions.financialProfessionalNextPage(dispatch, getFields());
        }
    }

    function getFields() {
        return ({
            ...fpGlobal,
            fpFirstName,
            fpMiddleName,
            fpLastName,
            fpCrdId,
            workforceId
        })
    }

    useImperativeHandle(ref, () => ({
        nextPage: nextPage,
        isValid: fpValid,
        getFields: getFields
    }));

    return (
        <>
            <div className="row util-padding-top-10">
                <InputBox
                    id='financialProfessional.fpCrdId'
                    label='CRD #'
                    value={fpCrdId}
                    onChange={setFpCrdId}
                    required={false}
                    readonly={editable}
                    maxLength={10}
                    integerOnly={true}
                />
            </div>
            <div className="row util-padding-top-10">
                <InputBox
                    id='financialProfessional.fpFirstName'
                    label='First name'
                    length='4'
                    required={true}
                    error={firstNameInvalid()}
                    value={fpFirstName}
                    onChange={setFpFirstName}
                    readonly={editable}
                    maxLength={50}
                />
                <InputBox
                    id='financialProfessional.fpMiddleName'
                    label='Middle name'
                    length='4'
                    value={fpMiddleName}
                    onChange={setFpMiddleName}
                    readonly={editable}
                    maxLength={50}
                />
                <InputBox
                    id='financialProfessional.fpLastName'
                    label='Last name'
                    length='4'
                    required={true}
                    error={lastNameInvalid()}
                    value={fpLastName}
                    onChange={setFpLastName}
                    readonly={editable}
                    maxLength={50}
                />
            </div>
        </>
    );
})

export default FinancialProfessional;