import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {actions, ADD_NEW_PAGES} from "../../data/reducer";
import {useParams} from 'react-router-dom'
import FinancialProfessional from "../checkblotter/FinancialProfessional";
import StepIndicator from "../checkblotter/StepIndicator";
import ProductFunding from "../checkblotter/ProductFunding";
import DocumentInformation from "../checkblotter/DocumentInformation";
import Summary from "../checkblotter/Summary";
import StepNavigationButtons from "../checkblotter/StepNavigationButtons";
import Workflows from "../checkblotter/Workflows";
import {DOCUMENT_TYPE_CHECK, DOCUMENT_TYPE_STOCK, URL_PARAM_STOCK} from "../../data/constants";
import {getDocumentText} from "../../data/utils";
import FinancialProfessionalSearch from "../checkblotter/FinancialProfessionalSearch";
import ErrorDisplay from "../form/ErrorDisplay";

export default function AddNewPage(props) {

    const addNewCurrentPage = useSelector(state => state.addNewCurrentPage);
    const errors = useSelector(state => state.errors);

    const {documentType} = useParams();
    const dispatch = useDispatch();

    const currentPageName = ADD_NEW_PAGES[addNewCurrentPage];
    const pageRef = useRef(null);

    const [loading, setLoading] = useState(false);
    const [selectedProfessional, setSelectedProfessional] = useState(false)

    useEffect(() => {
        if (loading) {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPageName, errors])

    useEffect(() => {
        let dt = documentType === URL_PARAM_STOCK ? DOCUMENT_TYPE_STOCK : DOCUMENT_TYPE_CHECK
        actions.updateValue(dispatch, "documentType", dt)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentType])

    const Step = ({header}) => {
        return (
            <>
                <div className="page-header">
                    <h1>{header}</h1>
                </div>
                <div className="container row">
                    <small className="is-required-header pull-right">Required</small>
                </div>
            </>
        )
    }
    return (
        <div className="container util-padding-bottom-20">
            <StepIndicator currentPageIndex={addNewCurrentPage}/>
            <ErrorDisplay/>
            <main role="form">
                <div className="container row">
                    {currentPageName === 'Financial professional' &&
                    <>
                        <div className="page-header">
                            <h1>{"Select financial professional"}</h1>
                        </div>

                        {!selectedProfessional &&
                        <FinancialProfessionalSearch
                            onSelection={(financialProfessional) => {
                                actions.saveFinancialProfessional(dispatch, financialProfessional)
                                setSelectedProfessional(true)
                            }}
                        />
                        }
                        {selectedProfessional &&
                        <FinancialProfessional
                            editable={false}
                            ref={pageRef}
                        />
                        }
                    </>
                    }
                    {currentPageName !== "Summary" &&
                    <form onSubmit={(e) => {
                        if (pageRef.current.isValid()) {
                            setLoading(true);
                        }
                        pageRef.current.nextPage(e);
                    }}>
                        {currentPageName === 'Check information' &&
                        <>
                            <Step header={`${getDocumentText(documentType)} information`}/>
                            <DocumentInformation
                                editable={!loading}
                                ref={pageRef}
                            />
                        </>
                        }
                        {currentPageName === 'Products' &&
                        <>
                            <Step header={'Products'}/>
                            <ProductFunding
                                editable={!loading}
                                ref={pageRef}
                            />
                        </>
                        }
                        {currentPageName === 'Check history' &&
                        <>
                            <Step header={`${getDocumentText(documentType)} history`}/>
                            <Workflows
                                editable={!loading}
                                ref={pageRef}
                                access={false}
                            />
                        </>
                        }
                        <div className="row container util-padding-top-10">
                            <StepNavigationButtons
                                onPreviousPage={currentPageName !== 'Financial professional' ? (e) => pageRef.current.previousPage(e) : (selectedProfessional ? () => setSelectedProfessional(false) : false)}
                                onNextPage={selectedProfessional}
                                nextButtonText={currentPageName === 'Check history' ? "Save" : undefined}
                                loading={loading}
                            />
                        </div>
                    </form>
                    }
                    {currentPageName === 'Summary' &&
                    <Summary
                        setSelectedProfessional={setSelectedProfessional}
                    />
                    }
                </div>
            </main>
        </div>
    );
}
