import {search} from "../../data/api";
import React, {useState} from "react";
import SearchResults from "../checkblotter/SearchResults";
import InputBox from "../form/InputBox";
import Button from "../form/Button";
import InlineRadio from "../form/InlineRadio";
import DateInputBox from "../form/DateInputBox";
import DollarAmountInputBox from "../form/DollarAmountInputBox";
import {useDispatch, useSelector} from "react-redux";
import {getDocumentText, isEncoded} from "../../data/utils";
import DecimalInputBox from "../form/DecimalInputBox";
import {DOCUMENT_TYPE_CHECK, DOCUMENT_TYPE_STOCK} from "../../data/constants";
import {actions} from "../../data/reducer";
import ErrorDisplay from "../form/ErrorDisplay";

export default function SearchPage(props) {

    const workforceId = useSelector(state => state.form.workforceId);
    const dispatch = useDispatch();

    const [documentType, setDocumentType] = useState(DOCUMENT_TYPE_CHECK);
    const [documentNumber, setDocumentNumber] = useState('');
    const [documentDate, setDocumentDate] = useState('');
    const [payeeName, setPayeeName] = useState('');
    const [checkAmount, setCheckAmount] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [shareTotal, setShareTotal] = useState('');
    const [endDate, setEndDate] = useState('');
    const [documentReceivedDate, setDocumentReceivedDate] = useState('');
    const [repCrd, setRepCrd] = useState('')

    const [searchByRange, setSearchByRange] = useState(false);
    const searchByRangeOptions = [{id: "y", text: "Yes"}, {id: "n", text: "No"}];
    const searchByDocumentTypeOptions = [{id: DOCUMENT_TYPE_CHECK, text: "Check"}, {
        id: DOCUMENT_TYPE_STOCK,
        text: "Stock Certificate"
    }]
    const documentText = getDocumentText(documentType)

    const [showAllErrors, setShowAllErrors] = useState(false);

    const [searchRan, setSearchRan] = useState(false);
    const [results, setResults] = useState([]);
    const [documentTypeResults, setDocumentTypeResults] = useState('');
    const [loading, setLoading] = useState(false);

    function fetchSearchResults(event) {
        event.preventDefault();
        if (isValid()) {
            actions.clearErrors(dispatch)
            setLoading(true)
            setShowAllErrors(false)
            setSearchRan(true);
            const searchCriteria = {
                documentType,
                documentNumber,
                'documentDate': searchByRange ? '' : documentDate,
                payeeName,
                checkAmount,
                companyName,
                shareTotal,
                documentReceivedDate,
                'beginDate': searchByRange ? documentDate : '',
                endDate,
                'fpCrdId': repCrd,
                workforceId
            }
            search(searchCriteria, data => {
                setResults(data.data);
                setDocumentTypeResults(documentType);
                setLoading(false)
                actions.clearErrors(dispatch)
            }, error => {
                actions.updateErrors(dispatch, error)
                setLoading(false)
            })
        } else {
            if (isValidInput() === false) {
                actions.updateErrors(dispatch, [{
                    field: "none",
                    error: "Search criteria has invalid characters"
                }])
                setLoading(false);
                setShowAllErrors(true);
            } else {
                actions.updateErrors(dispatch, [{field: "none", error: "Search criteria is required"}])
                setLoading(false);
                setShowAllErrors(true);
            }
        }
    }

    function criteriaExists() {
        return documentNumber.length > 0 ||
            documentDate.length > 0 ||
            payeeName.length > 0 ||
            checkAmount.length > 0 ||
            endDate.length > 0 ||
            documentReceivedDate.length > 0 ||
            companyName.length > 0 ||
            shareTotal.length > 0 ||
            repCrd.length > 0
    }

    function isValid() {
        return criteriaExists() &&
            isValidInput() &&
            (searchByRange ? (endDate.length > 0 && documentDate.length !== 0) : true) &&
            (searchByRange ? new Date(endDate) >= new Date(documentDate) : true);
    }

    function isValidInput() {
        return isEncoded(documentNumber) &&
            isEncoded(payeeName) &&
            isEncoded(repCrd) &&
            isEncoded(companyName);
    }

    const documentDateInvalid = () => {
        if (endDate.length > 0 && documentDate.length === 0) {
            return showAllErrors ? 'Must provide a start date' : true
        } else {
            return false
        }
    }

    const endDateInvalid = () => {
        if (searchByRange && endDate.length === 0 && documentDate.length > 0) {
            return 'Must provide an end date to search by range'
        } else if (new Date(endDate) < new Date(documentDate)) {
            return 'End date must be after begin date'
        } else {
            return false
        }
    }

    return (
        <div className="row">
            <div className="container">
                <main role={"search"}>

                    <div className="page-header">
                        <h1>Search</h1>
                    </div>
                    <ErrorDisplay/>
                    <form onSubmit={fetchSearchResults}>
                        <div className="row util-padding-top-10">
                            <div className="col-md-3">
                                <span><b>Search for documents of type:</b></span>
                            </div>
                        </div>
                        <div className="row util-padding-top-10">
                            <InlineRadio
                                id="documentType"
                                options={searchByDocumentTypeOptions}

                                onChange={(e) => setDocumentType(e)}
                                defaultValue={documentType}/>
                        </div>
                        <div className="row util-padding-top-10">
                            <InputBox
                                id='documentNumber'
                                label={`${documentText} number`}
                                length='4'
                                value={documentNumber}
                                onChange={setDocumentNumber}
                            />
                            {documentType === DOCUMENT_TYPE_CHECK &&
                            <>
                                <InputBox
                                    id='payeeName'
                                    label="Check payee"
                                    length='4'
                                    value={payeeName}
                                    onChange={setPayeeName}
                                />
                                <DollarAmountInputBox
                                    id='checkAmount'
                                    label="Check amount"
                                    length='4'
                                    value={checkAmount}
                                    onChange={setCheckAmount}
                                />
                            </>
                            }
                            {documentType === DOCUMENT_TYPE_STOCK &&
                            <>
                                <InputBox
                                    id='companyName'
                                    label="Company name"
                                    length='4'
                                    value={companyName}
                                    onChange={setCompanyName}
                                />
                                <DecimalInputBox
                                    id='shareTotal'
                                    label="Share total"
                                    length='4'
                                    value={shareTotal}
                                    onChange={setShareTotal}
                                />
                            </>
                            }
                        </div>

                        <div className="row util-padding-top-10">
                            <DateInputBox
                                id='documentDate'
                                label={searchByRange ? 'Begin date' : `${documentText} date`}
                                length='4'
                                value={documentDate}
                                onChange={setDocumentDate}
                                error={documentDateInvalid()}
                            />
                            {searchByRange &&
                            <DateInputBox
                                id='endDate'
                                label='End date'
                                length='4'
                                value={endDate}
                                onChange={setEndDate}
                                error={endDateInvalid()}/>
                            }
                            {props.group.HOME &&
                            <DateInputBox
                                id='documentReceivedDate'
                                label={`${documentText} received date`}
                                length='4'
                                value={documentReceivedDate}
                                onChange={setDocumentReceivedDate}
                            />
                            }
                            <InputBox
                                id={'repCrd'}
                                label={"Rep CRD"}
                                length={'4'}
                                value={repCrd}
                                onChange={setRepCrd}
                            />
                        </div>
                        <div className="row util-padding-top-10">
                            <div className="col-md-2">
                                <span>Search by range?</span>
                            </div>
                            <div className="col-md-4">
                                <InlineRadio
                                    id="isSplitRadio"
                                    options={searchByRangeOptions}
                                    onChange={(e) => {
                                        if (e === 'y') {
                                            setSearchByRange(true);
                                            setEndDate(String(new Date().toISOString().substring(0, 10)));
                                        }
                                        if (e === 'n') {
                                            setSearchByRange(false)
                                            setEndDate('');
                                        }
                                    }}
                                    defaultValue={searchByRange ? 'y' : 'n'}/>
                            </div>
                        </div>

                        <div className="row util-padding-top-10">
                            <div className="col-md-12">
                                <Button loading={loading} type='submit' text='Search'/>
                            </div>
                        </div>
                    </form>
                    {searchRan && !loading &&
                    <>
                        <hr role="presentation"/>
                        <div className="row util-padding-top-10">
                            <div className="col-lg-12">
                                <SearchResults results={results} documentTypeResults={documentTypeResults}
                                               access={props.access}/>
                            </div>
                        </div>
                    </>
                    }

                </main>
            </div>
        </div>
    );
}
