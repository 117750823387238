import {PropTypes} from 'prop-types';
import React from "react";

export default function ErrorWrapper(props) {

    return (
        <div className={props.error && props.error.length > 0 ? " has-error" : ""}>
            {props.children}
            <div>
                <span className="has-error-message help-block">{" " + props.error}</span>
            </div>
        </div>
    );
}

ErrorWrapper.propTypes = {
    error: PropTypes.any
}