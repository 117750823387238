import Navbar from "./Navbar";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import SearchPage from "./pages/SearchPage";
import AddNewPage from "./pages/AddNewPage";
import {configureAxios, fetchDomains} from "../data/api";
import React, {useEffect, useState} from "react";
import {useUser} from "@principal-aws-oidc-auth/react";
import {useDispatch} from "react-redux";
import DocumentPage from "./pages/DocumentPage";
import Logout from "./pages/Logout";
import {FIELD_EDIT, HOME_EDIT, HOME_READ_ONLY, URL_PARAM_CHECK} from "../data/constants";
import jwt_decode from "jwt-decode";
import {actions} from "../data/reducer";
import {useInterval} from "../data/UseInterval"
import axios from "axios";

export default function Page(props) {

    const dispatch = useDispatch();

    const user = useUser();
    const [role, setRole] = useState(undefined)
    const [group, setGroup] = useState({})
    const [access, setAccess] = useState({})
    const [refresh, setRefresh] = useState(undefined);

    useEffect(() => {
        if (user != null) {
            const decoded = jwt_decode(user.access_token)
            let userRole = decoded.roles[0];
            setRole(userRole)
            actions.updateWorkforceId(dispatch, decoded.onPremisesSamAccountName)
            setGroup({
                FIELD: [FIELD_EDIT].some(r => r === userRole),
                HOME: [HOME_EDIT, HOME_READ_ONLY].some(r => r === userRole)
            })
            setAccess({
                ADD_WORKFLOW: [HOME_EDIT].some(r => r === userRole),
                EDIT_DOCUMENT: [HOME_EDIT, FIELD_EDIT].some(r => r === userRole),
                ADD_DOCUMENT: [HOME_EDIT, FIELD_EDIT].some(r => r === userRole),
                SEARCH: true
            })
            setRefresh(user.refresh_token)

            configureAxios(user);
            fetchDomains(dispatch);
            idleTimer();
        }

    }, [user, role, dispatch]);

    useInterval(() => {
        refreshToken();
    }, 600000);

    function refreshToken() {
        const clientId = window.location.hostname === 'psicheckblotter.principal.com' ? 'c99f262e-9497-411d-90c1-7793c46b87f7' : '21a53bdf-e89f-44f2-80e3-47b29cdc78be';
        const tenantId = window.location.hostname === 'psicheckblotter.principal.com' ? '3bea478c-1684-4a8c-8e85-045ec54ba430' : '50abac53-da5d-44dc-8586-587ec2d3d097';

        const url = `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/token`;

        let bodyFormData = new FormData();
        bodyFormData.append('grant_type', 'refresh_token');
        bodyFormData.append('refresh_token', refresh);
        bodyFormData.append('client_id', clientId);
        bodyFormData.append('scope', 'api://psi-check-blotter/read api://psi-check-blotter/write');

        axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.access_token;
        axios({
            method: 'post',
            url: url,
            data: bodyFormData
        }).then(data => {
            setRefresh(data.data.refresh_token);
            user.access_token = data.data.access_token;
            user.refresh_token = data.data.refresh_token;
            configureAxios(user);
        }).catch(() => {
        });

    }

    function idleTimer() {
        let inactivityTime = function () {
            let time;
            time = setTimeout(logout, 60000 * 20)
            window.onmousemove = resetTimer; // catches mouse movements
            window.onmousedown = resetTimer; // catches mouse movements
            window.onclick = resetTimer;     // catches mouse clicks
            window.onscroll = resetTimer;    // catches scrolling
            window.onkeypress = resetTimer;  //catches keyboard actions

            function logout() {
                alert("You are about to be logged out");
                window.location.href = "/logout";
            }

            function resetTimer() {
                clearTimeout(time);
                time = setTimeout(logout, 60000 * 20)  //set to 20 minutes
            }
        };
        window.onload = function () {
            inactivityTime();
        }
    }

    return (
        user ? <>
                <Navbar access={access}/>
                {/*<div className="alert alert-warning alert-icon alert-dismissible container" role="alert">
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span
                        aria-hidden="true">&times;</span></button>
                    <strong>Warning!</strong> There will be downtime on January 10th beginning at 6:30pm CST. Do not add or modify data during this period to avoid losing data.
                </div>*/}
                {role ?
                    <Router>
                        <Switch>
                            <Route path="/document/:id">
                                <DocumentPage access={access}/>
                            </Route>
                            <Route path="/search">
                                <SearchPage access={access} group={group}/>
                            </Route>
                            <Route path="/addnew/:documentType">
                                {!access.ADD_DOCUMENT && <Redirect to="/search"/>}
                                <AddNewPage/>
                            </Route>
                            <Route path="/addnew">
                                <Redirect to={`/addnew/${URL_PARAM_CHECK}`}/>
                            </Route>
                            <Route exact path="/">
                                {group.HOME && <Redirect to="/search"/>}
                                {group.FIELD && access.ADD_DOCUMENT
                                    ? <Redirect to="/addnew"/>
                                    : <Redirect to="/search"/>
                                }
                            </Route>
                            <Route path="/logout">{<Logout/>}</Route>
                        </Switch>
                    </Router>
                    :
                    <h1>{`You have not been granted access to any features on this site `}</h1>
                }
            </>
            :
            <h1>Please wait...</h1>
    );
}