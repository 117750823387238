import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import InputBox from "../form/InputBox";
import {useDispatch, useSelector} from "react-redux";
import {actions} from "../../data/reducer";
import {
    addDaysToDate,
    formatDecimals,
    formatDocumentTotal,
    getDocumentText,
    getNewDateInLocalTimezone,
    isEncoded,
    stringEmpty
} from "../../data/utils";
import DateInputBox from "../form/DateInputBox";
import DollarAmountInputBox from "../form/DollarAmountInputBox";
import {DOCUMENT_TYPE_STOCK} from "../../data/constants";

const DocumentInformation = forwardRef((props, ref) => {

    const dispatch = useDispatch();

    const documentInformationGlobal = useSelector(state => state.form.documentInformation);
    const formGlobal = useSelector(state => state.form)
    const documentType = useSelector(state => state.form.documentType)
    const documentText = useSelector(state => getDocumentText(state.form.documentType))

    const [documentNumber, setDocumentNumber] = useState('');
    const [documentDate, setLocalDocumentDate] = useState('');
    const [documentTotal, setLocalDocumentTotal] = useState('');
    const [documentPayee, setDocumentPayee] = useState('');

    const editable = props.editable === undefined ? false : !props.editable;
    const [showAllErrors, setShowAllErrors] = useState(false);

    useEffect(() => {
        if (documentInformationGlobal && formGlobal) {
            let {documentTotal, documentPayee} = documentInformationGlobal
            setDocumentNumber(formGlobal.documentNumber);
            setDocumentDate(formGlobal.documentDate);
            setDocumentTotal(documentType === DOCUMENT_TYPE_STOCK
                ? formatDocumentTotal(formGlobal.productFundings, documentTotal, documentType)
                : formatDecimals(documentTotal, 2));
            setDocumentPayee(documentPayee);
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentInformationGlobal, formGlobal]);

    const setDocumentDate = (date) => {
        setLocalDocumentDate(date)
        actions.updateMatch(dispatch, "documentDate", date)
    }

    const setDocumentTotal = (total) => {
        setLocalDocumentTotal(total)
        actions.updateMatch(dispatch, "documentTotal", total)
    }

    const documentDateInvalid = () => {
        if (stringEmpty(documentDate) || isNaN(new Date(documentDate))) {
            return showAllErrors ? `${documentText} date is required.` : true;
        } else if (getNewDateInLocalTimezone(documentDate) > addDaysToDate(new Date(), 14)) {
            return `${documentText} date cannot be post dated more than 14 days`;
        } else {
            return false;
        }
    }

    const documentTotalInvalid = () => {
        if (stringEmpty(documentTotal)) {
            return showAllErrors ? `${getDocumentText(documentType, "Check amount", "Share total")} is required.` : true;
        } else if (Number(documentTotal) <= 0) {
            return showAllErrors ? `${getDocumentText(documentType, "Check amount", "Share total")} cannot be zero` : true;
        } else {
            return false;
        }
    }

    const documentNumberInvalid = () => {
        if (stringEmpty(documentNumber)) {
            return showAllErrors ? `${documentText} number is required.` : true;
        } else if (!isEncoded(documentNumber)) {
            return showAllErrors ? `${documentText} number has invalid characters.` : true;
        } else {
            return false;
        }

    }
    const payeeInvalid = () => {
        if (stringEmpty(documentPayee)) {
            return showAllErrors ? `${getDocumentText(documentType, "Check payee", "Company name")} is required.` : true;
        } else if (!isEncoded(documentPayee)) {
            return showAllErrors ? `${getDocumentText(documentType, "Check payee", "Company name")} has invalid characters.` : true;
        } else {
            return false;
        }
    }

    function documentInformationValid() {
        setShowAllErrors(true)
        return !(!!documentDateInvalid() ||
            !!documentTotalInvalid() ||
            !!documentNumberInvalid() ||
            !!payeeInvalid());
    }

    function nextPage(event) {
        event.preventDefault();
        if (documentInformationValid()) {
            actions.documentInformationNextPage(dispatch,
                {documentTotal, documentPayee}, documentDate, documentNumber);
        }
    }

    function previousPage() {
        actions.documentInformationPreviousPage(dispatch,
            {documentTotal, documentPayee}, documentDate, documentNumber);
    }

    function getFields() {
        return ({
            documentInformation: {
                ...documentInformationGlobal,
                documentTotal,
                documentPayee
            },
            documentDate,
            documentNumber
        })
    }

    useImperativeHandle(ref, () => ({
        nextPage: nextPage,
        previousPage: previousPage,
        isValid: documentInformationValid,
        getFields: getFields
    }))

    return (
        <>
            <div className="row util-padding-top-10">
                <InputBox
                    id={`documentNumber`}
                    label={`${documentText} number`}
                    required={true}
                    error={documentNumberInvalid()}
                    value={documentNumber}
                    readonly={editable}
                    onChange={setDocumentNumber}
                    maxLength={50}
                />
                <DateInputBox
                    id={`documentDate`}
                    label={`${documentText} date`}
                    required={true}
                    error={documentDateInvalid()}
                    value={documentDate}
                    editable={editable}
                    readonly={editable}
                    onChange={setDocumentDate}
                    minYear={documentType === DOCUMENT_TYPE_STOCK ? "1900" : null}
                />
            </div>
            <div className="row util-padding-top-10">
                <DollarAmountInputBox
                    id={getDocumentText(documentType, "checkAmount", "shareTotal")}
                    label={getDocumentText(documentType, "Check amount", "Share total")}
                    required={true}
                    error={documentTotalInvalid()}
                    value={documentTotal}
                    editable={editable}
                    readonly={editable}
                    onChange={setDocumentTotal}
                    decimals={documentType === DOCUMENT_TYPE_STOCK ? 6 : 2}
                    hideDollarSign={documentType === DOCUMENT_TYPE_STOCK}
                />
                <InputBox
                    id={getDocumentText(documentType, "checkPayee", "companyName")}
                    label={getDocumentText(documentType, "Check payee", "Company name")}
                    required={true}
                    error={payeeInvalid()}
                    value={documentPayee}
                    editable={editable}
                    readonly={editable}
                    onChange={setDocumentPayee}
                    maxLength={50}
                />
            </div>
        </>
    )
})

export default DocumentInformation;
