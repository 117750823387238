import {PropTypes} from "prop-types";
import React from "react";
import ErrorWrapper from "./ErrorWrapper";

export default function Select(props) {

    const defaultDropDownValue = {
        id: '0',
        text: ''
    };

    const items = props.items ? [defaultDropDownValue, ...props.items] : [{id: '0', text: 'Loading...'}];

    const length = props.length ? props.length : "6";
    const disable = props.disabled ? props.disabled : false;

    return (
        <div className={"col-md-" + length}>
            <ErrorWrapper error={props.error}>
            <label htmlFor={props.id} className={props.required ? 'is-required' : '' }>{props.label}</label>{"  "}
            <select className="form-control"
                    id={props.id}
                    value={props.value || ''}
                    disabled={disable}
                    onChange={event => {
                        const valueToSet = event.target.value === defaultDropDownValue.id ? undefined : event.target.value;
                        props.onChange(valueToSet);
                    }}>
                {
                    items.map(item =>
                        <option key={item.id} value={item.id}>{item.text}</option>
                    )
                }
            </select>
            </ErrorWrapper>
        </div>
    );
}

Select.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    items: PropTypes.array,
    required: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool
}