import React from "react";
import ErrorWrapper from "./ErrorWrapper";
import {PropTypes} from "prop-types";

export default function DateInputBox(props){

    const length = props.length ? props.length : "6";
    const maxYear = props.maxYear || new Date().getFullYear() + 1
    const minYear = props.minYear || new Date().getFullYear() - 3

    return (
        <div className={"col-md-" + length}>
            <ErrorWrapper error={props.error}>
                <label htmlFor={props.id} className={props.required ? 'is-required' : ''}>{props.label}</label>
                <div className="input-group">
                    <input type='date'
                           id={props.id}
                           className="form-control"
                           value={props.value || ''}
                           onChange={(x) => {
                               props.onChange(x.target.value)
                           }}
                           readOnly={!!props.readonly}
                           max={`${maxYear}-12-31`}
                           min={`${minYear}-01-01`}
                    />
                    <span className="input-group-btn">
                        <button className="btn btn-ghost-primary" type="button"
                                disabled={!!props.readonly ? 'disabled' : undefined}
                                onClick={event => props.onChange('')}
                                aria-label="Clear"
                        ><span aria-hidden="true">&times;</span></button>
                    </span>
                </div>
            </ErrorWrapper>
        </div>
    );

}

DateInputBox.propTypes = {
    length: PropTypes.string,
    id: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    readonly: PropTypes.bool
}