import {PropTypes} from "prop-types";
import React from "react";

export default function CheckBox(props) {

    return (
        <div className="checkbox styled-checkbox">
            <input id={props.id}
                   type="checkbox"
                   className="form-control"
                   checked={props.checked || false}
                   onChange={event => props.onChecked(event.target.checked)}
                   disabled={!!props.disabled}
            />
            <label htmlFor={props.id}>{props.label}</label>
        </div>
    );
}

CheckBox.propTypes = {
    length: PropTypes.string,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    onChecked: PropTypes.func
}