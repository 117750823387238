import React, {useEffect, useRef, useState} from "react";
import {useParams} from 'react-router-dom'
import {useDispatch, useSelector} from "react-redux";
import {actions} from "../../data/reducer";
import FinancialProfessional from "../checkblotter/FinancialProfessional";
import ProductFunding from "../checkblotter/ProductFunding";
import DocumentInformation from "../checkblotter/DocumentInformation"
import Workflows from "../checkblotter/Workflows"

import {getDocument} from "../../data/api";
import Button from "../form/Button";
import {convertDepositAmount, convertDocumentInformation, getDocumentText} from "../../data/utils";
import FinancialProfessionalSearch from "../checkblotter/FinancialProfessionalSearch";
import ErrorDisplay from "../form/ErrorDisplay";

export default function DocumentPage(props) {

    const dispatch = useDispatch();
    const workforceId = useSelector(state => state.form.workforceId);

    const [allowEdit, setAllowEdit] = useState(false);
    const [databaseData, setDatabaseData] = useState({});
    const {id} = useParams();

    const documentText = useSelector(state => getDocumentText(state.form.documentType))
    const [selectedProfessional, setSelectedProfessional] = useState(true)

    const [successfulEdit, setSuccessfulEdit] = useState(undefined)
    const [retrieveError, setRetrieveError] = useState(undefined)
    const [loading, setLoading] = useState(undefined)

    const financialProfessionalRef = useRef(null);
    const documentInformationRef = useRef(null);
    const productFundingsRef = useRef(null);
    const workflowsRef = useRef(null);
    const [headerRefs] = useState([])

    const [documentNumber, setDocumentNumber] = useState(undefined)
    const parseData = (data) => {
        setDatabaseData(data)
        actions.saveFinancialProfessional(dispatch, {...data.financialProfessional})

        setDocumentNumber(data.documentNumber)
        actions.saveDocumentInformation(dispatch,
            convertDocumentInformation(data.stockCertificateInformation || data.checkInformation), data.documentDate, data.documentNumber)

        const productFundings = convertDepositAmount(data.productFundings).map((funding) => {
            const productType = funding.product.productType.id || funding.product.productType;
            const clients = funding.product.clients.map((client) => {
                return ({
                    ...client,
                    clientType: client.clientType.id || client.clientType
                })
            })
            return ({...funding, product: {...funding.product, productType, clients}})
        })
        actions.saveProductFundings(dispatch, productFundings)

        const workflows = data.workflows.map((wf) => {
            const documentReceivedLocation = wf.documentReceivedLocation ?
                wf.documentReceivedLocation.id || wf.documentReceivedLocation : undefined
            const documentDestination = wf.documentDestination ? wf.documentDestination.id || wf.documentDestination : undefined
            const documentSentDate = wf.documentSentDate || undefined
            return ({
                ...wf,
                documentReceivedLocation,
                documentSentDate,
                documentDestination
            })
        })
        actions.saveWorkflows(dispatch, workflows)

    }
    useEffect(() => {
        function actionAfterFetch(data) {
            parseData(data.data)
            data.data && actions.updateValue(dispatch, "documentType", data.data.documentType.id)
        }

        //double fetch is a work around for issues with hard reloading the page.
        // it is not needed when navigating from other pages in the site
        getDocument({"documentId": id, workforceId}, data => {
            actionAfterFetch(data)
        }, err => {

            getDocument({"documentId": id, workforceId}, data => {
                actionAfterFetch(data)
            }, err => {
                setRetrieveError({
                    status: (err.request && err.request.status) || '404',
                    statusText: (err.request && err.request.statusText) || 'Document Id not found'
                })
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function editFunction() {
        if (allowEdit) {
            setSelectedProfessional(true);
            parseData(databaseData)
            actions.clearErrors(dispatch)
            setAllowEdit(false);
        } else {
            setAllowEdit(true)
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }
        setSuccessfulEdit(false)
    }

    const isValid = () => {
        let options = {block: "start", inline: "nearest", behavior: 'smooth'}
        let wfGood = workflowsRef.current.isValid() || headerRefs[3].current.scrollIntoView(options)
        let pfGood = productFundingsRef.current.isValid() || headerRefs[2].current.scrollIntoView(options)
        let docInfoGood = documentInformationRef.current.isValid() || headerRefs[1].current.scrollIntoView(options)
        let fpGood = financialProfessionalRef.current.isValid() || headerRefs[0].current.scrollIntoView(options)
        return (fpGood && docInfoGood && pfGood && wfGood)
    }

    const editDocument = (event) => {
        event.preventDefault();
        //todo this seems to be changing it right but there isnt a long enough pause for is valid to recognize the new value
        if (isValid()) {
            setLoading(true)
            setAllowEdit(false)
            let financialProfessional = financialProfessionalRef.current.getFields();
            actions.saveFinancialProfessional(dispatch, financialProfessional)
            let documentInformation = documentInformationRef.current.getFields();
            actions.saveDocumentInformation(dispatch, documentInformation.documentInformation, documentInformation.documentDate, documentInformation.documentNumber)
            let productFundings = productFundingsRef.current.getFields();
            actions.saveProductFundings(dispatch, productFundings)
            let workflows = workflowsRef.current.getFields();
            actions.saveWorkflows(dispatch, workflows)
            let form = {
                documentType: databaseData.documentType.id,
                documentId: id,
                financialProfessional,
                documentDate: documentInformation.documentDate,
                documentNumber: documentInformation.documentNumber,
                documentInformation: documentInformation.documentInformation,
                productFundings,
                workflows
            }
            actions.editDocument(dispatch, form, (data) => {
                setSuccessfulEdit(true)
                setAllowEdit(false)
                setSelectedProfessional(true)
                setLoading(false)
                setDatabaseData(data.data)
                parseData(data.data)
                actions.clearErrors(dispatch)
            }, (errors) => {
                setAllowEdit(true)
                setLoading(false)
                setSuccessfulEdit(false)
            })
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }
    }


    const StepContainer = (props) => {
        const headerRef = useRef()
        headerRefs[props.id] = headerRef
        return (
            <div className="container">
                <div className="container row util-padding-top-10">
                    <h2 ref={headerRef}>{props.header}</h2>
                </div>
                <div className={"bg-light-blue util-padding-10"}>
                    {props.children}
                </div>
            </div>
        )
    }

    //todo it would be cool for them to be able to go back to search and have their past search saved
    return (
        <div className="container util-padding-bottom-20">
            <header>
                <div className="container row page-header">
                    {retrieveError &&
                    <div className="alert alert-danger alert-icon" role="alert">
                        {`Error ${retrieveError.status}: ${retrieveError.statusText}`}
                    </div>
                    }
                    <h1>
                        {documentNumber && `${documentText} ${documentNumber}`}
                        {!documentNumber && !retrieveError &&
                        <span>
                        {"Loading "}
                            <i className="fa fa-spinner fa-spin"/>
                        </span>
                        }
                    </h1>
                </div>
                <ErrorDisplay/>
                {successfulEdit &&
                <div className="row">
                    <div className="col-md-offset-1 col-md-10">
                        <div className="alert alert-success alert-icon"
                             role="alert">{`${documentText} ${documentNumber}`} was successfully
                            updated
                        </div>
                    </div>
                </div>
                }
            </header>
            <main>
                {!retrieveError &&
                <form onSubmit={(e) => editDocument(e)}>
                    <div className="form-group">
                        <small className="is-required-header pull-right">Required</small>
                    </div>
                    <div className={"row"}>

                        <StepContainer id="0" header={"Financial professional"}>
                            {!selectedProfessional &&
                            <FinancialProfessionalSearch
                                onSelection={(financialProfessional) => {
                                    actions.saveFinancialProfessional(dispatch, financialProfessional)
                                    setSelectedProfessional(true)
                                }}
                            />
                            }
                            {selectedProfessional &&
                            <>
                                <FinancialProfessional
                                    editable={false}
                                    ref={financialProfessionalRef}
                                />
                                {allowEdit &&
                                <div className={"row  util-padding-15"}>
                                    <Button
                                        text={"Edit financial professional"}
                                        onClick={() => setSelectedProfessional(false)}
                                        className={"pull-right"}
                                    />
                                </div>
                                }
                            </>
                            }
                        </StepContainer>
                        <StepContainer id="1" header={`${documentText} information`}>
                            <DocumentInformation
                                editable={allowEdit}
                                ref={documentInformationRef}
                            />
                        </StepContainer>
                        <StepContainer id="2" header={"Product information"}>
                            <ProductFunding
                                editable={allowEdit}
                                ref={productFundingsRef}
                            />
                        </StepContainer>
                        <StepContainer id="3" header={`${documentText} history`}>
                            <Workflows
                                editable={allowEdit}
                                setAllowEdit={setAllowEdit}
                                ref={workflowsRef}
                                access={props.access}
                            />
                        </StepContainer>
                    </div>
                    <div className="container row util-padding-top-10">
                        {(props.access.EDIT_DOCUMENT || allowEdit) &&
                        <div className="pull-left">
                            <Button
                                text={allowEdit || loading ? 'Cancel' : 'Edit'}
                                onClick={editFunction}
                                loading={loading}
                            />
                        </div>
                        }
                        {(allowEdit || loading) &&
                        <div className="pull-right">
                            <Button
                                type='submit'
                                text='Save'
                                loading={loading}
                                disabled={!selectedProfessional}
                            />
                        </div>
                        }
                    </div>
                </form>
                }
            </main>
        </div>
    );
}
