import React from "react";
import {URL_PARAM_CHECK, URL_PARAM_STOCK} from "../data/constants";

export default function Navbar(props) {
    return (
        <nav className="navbar navbar-default navbar-fixed-top">
            <div className="container">
                <div className="navbar-header">
                    <button type="button" className="navbar-toggle collapsed" data-toggle="collapse"
                            data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                        <span className="sr-only">Toggle navigation</span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                    </button>
                    <a className="navbar-principal-brand" aria-hidden="true" tabIndex='-1' href="/">Logo</a>
                </div>

                <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                    <ul className="nav navbar-nav">
                        {props.access.ADD_DOCUMENT &&
                        <>
                            <li className="dropdown">
                                <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                    Add new <i className="fa fa-angle-down"></i>
                                </a>
                                <ul className="dropdown-menu" role="menu">
                                    <li><a href={`/addnew/${URL_PARAM_CHECK}`}>Check</a></li>
                                    <li><a href={`/addnew/${URL_PARAM_STOCK}`}>Stock Certificate</a></li>
                                </ul>
                            </li>
                        </>
                        }
                        {props.access.SEARCH &&
                        <li><a href="/search">Search</a></li>
                        }
                    </ul>
                    <ul className="nav navbar-nav navbar-right">
                        <li><a href="/">PSI Check Blotter</a></li>
                        <a href="/logout" className="pds-button pds-button-primary-light">Log out</a>
                    </ul>

                </div>
            </div>
        </nav>
    );
}