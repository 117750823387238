// domains.destinations IDs
export const REMOTE_DEPOSIT_ID = "4";
export const RETURNED_TO_SENDER_ID = "5";
export const MAILED_TO_FIDELITY_ID = "6";
export const DESKTOP_DEPOSIT_ID = "7"
// domains.productTypes IDs
export const GENERAL_BROKERAGE_ID = "1";
export const _529_BROKERAGE_ID = "7";
export const PSI_ADVISORY_ID = "9";
export const PRINCIPAL_ANNUITY_ID = "3";
export const FINANCIAL_PLANNING_ID = "11"
// domains.locations
export const HOME_OFFICE_ID = "2";
export const FIELD_OFFICE_ID = "1";
// domains.destinations
export const DESTINATION_CUSTODIAN_ID = "1";
// domains.clients
export const CLIENT_TYPE_PERSON_ID = "1";
export const CLIENT_TYPE_ORG_ID = "2";
// userRoles
export const FIELD_EDIT = "fieldOfficeEdit";
export const HOME_READ_ONLY = "homeOffice";
export const HOME_EDIT = "homeOfficeEdit";
// document types
export const DOCUMENT_TYPE_CHECK = "1";
export const DOCUMENT_TYPE_STOCK = "2";
export const URL_PARAM_STOCK = "stockcertificate";
export const URL_PARAM_CHECK = "check"
