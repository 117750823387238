/*
note: modifies date
 */
import {filter, join, trim} from "lodash";
import {DOCUMENT_TYPE_CHECK, DOCUMENT_TYPE_STOCK, URL_PARAM_CHECK, URL_PARAM_STOCK} from "./constants";


export function subtractDaysFromDate(date, numDays) {
    let dt = new Date(date.setDate(date.getDate() - numDays));
    dt.setHours(0, 0, 0, 0)
    return dt;
}

export function getNewDateInLocalTimezone(dateString = '') {
    if (!dateString) {
        return new Date()
    }
    const [year, month, day] = dateString.split("-");
    let dt = new Date(Number(year), Number(month) - 1, Number(day))
    return dt; // Date returned will be 00:00 of local time zone
}

/*
note: modifies date
 */
export function addDaysToDate(date, numDays) {
    let dt = new Date(date.setDate(date.getDate() + numDays));
    dt.setHours(0, 0, 0, 0)
    return dt;
}

export function dateIsWeekend(date) {
    return date.getDay() === 0 || date.getDay() === 6
}

export function businessDaysBetween(fromDate, toDate) {
    fromDate.setHours(0, 0, 0, 0)
    toDate.setHours(0, 0, 0, 0)
    let numBusinessDays = 0
    while (fromDate < toDate) {
        if (!dateIsWeekend(fromDate)) {
            numBusinessDays += 1
        }
        fromDate = addDaysToDate(fromDate, 1)
    }
    return numBusinessDays;
}

export function dateStringToMMddyyyy(dateString = '') {
    const [year, month, day] = dateString.split("-");
    return `${month}/${day}/${year}`
}

export function stringEmpty(str) {
    return trim(str).length === 0;
}

export function isEncoded(str) {
    return !str ? true : str.match(/^[-.'"&a-zA-Z0-9 ]*$/) != null;
}

export function formatUSD(inputString) {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });

    inputString = inputString && String(inputString).replace("$", "")
    return formatter.format(inputString)
}

export function getDocumentText(documentType, checkText = "Check", stockText = "Stock certificate", defaultText = "Document") {
    if (documentType === DOCUMENT_TYPE_CHECK || documentType === URL_PARAM_CHECK) {
        return checkText
    } else if (documentType === DOCUMENT_TYPE_STOCK || documentType === URL_PARAM_STOCK) {
        return stockText
    } else {
        return defaultText
    }
}

export function formatDecimals(inputString, length = 2) {
    if (!inputString) {
        return ''
    }
    inputString = String(inputString).replace("$", "")
    return Number(inputString).toFixed(Number(length))
}

export function roundOff(num, places) {
    const x = Math.pow(10, places);
    return Math.round(num * x) / x;
}

export function formatDocumentTotal(productFundings, documentTotal, documentType) {
    if (documentType === DOCUMENT_TYPE_STOCK) {
        return formatDecimals(documentTotal, getMaxDecimals(productFundings, documentTotal))
    } else {
        return formatUSD(documentTotal)
    }
}

export function formatDepositAmounts(productFundings, documentTotal, documentType) {
    const maxDecimals = getMaxDecimals(productFundings, documentTotal)
    return productFundings.map((pf) => {
            if (documentType === DOCUMENT_TYPE_STOCK) {
                pf.depositAmount = formatDecimals(pf.depositAmount, maxDecimals)
            } else {
                pf.depositAmount = formatUSD(pf.depositAmount)
            }
            return pf
        }
    )
}

export function getDecimals(number) {
    try {
        return String(number).split('.')[1] || ""
    } catch (TypeError) {
        return ""
    }
}

export function getMaxDecimals(productFundings, documentTotal) {
    return Math.max(
        ...(productFundings.map(pf => getDecimals(pf.depositAmount).length)
            .concat(getDecimals(documentTotal).length)))
}

export function convertDocumentInformation(documentInformation, documentType) {
    // pass no documentType to convert back to basic document info
    const {documentTotal, documentPayee, shareTotal, companyName, checkAmount, checkPayee} = documentInformation;
    let goodTotal = documentTotal || shareTotal || checkAmount;
    let goodPayee = documentPayee || companyName || checkPayee;
    if (documentType === DOCUMENT_TYPE_STOCK) {
        return {shareTotal: goodTotal, companyName: goodPayee, documentType}
    } else if (documentType === DOCUMENT_TYPE_CHECK) {
        return {checkAmount: goodTotal, checkPayee: goodPayee, documentType}
    } else {
        //if not documentType return document fields
        return {documentTotal: goodTotal, documentPayee: goodPayee}
    }
}

export function convertDepositAmount(productFundings, documentType) {
    // pass no documentType to convert back to basic document info
    return productFundings.map(pf => {
        const {depositAmount} = pf
        if (documentType === DOCUMENT_TYPE_STOCK) {
            return {...pf, depositAmount: null, shareAmount: depositAmount}
        } else if (documentType === DOCUMENT_TYPE_CHECK) {
            return {...pf, depositAmount: depositAmount, shareAmount: null}
        } else {
            //if not documentType return document fields
            let {shareAmount, ...stockPf} = pf
            return {...stockPf, depositAmount: shareAmount || depositAmount}
        }
    })
}

export function getFinancialProfessionalName(fp) {
    const names = filter([fp.fpFirstName, fp.fpMiddleName, fp.fpLastName], str => !stringEmpty(str));
    return join(names, ' ');
}