import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import ProductInformation from "./ProductInformation";
import Button from "../form/Button";
import InlineRadio from "../form/InlineRadio";
import {slice} from "lodash";
import {actions} from "../../data/reducer";
import {useDispatch, useSelector} from "react-redux";
import {formatDecimals, formatDepositAmounts, getDocumentText, roundOff} from "../../data/utils";
import {DOCUMENT_TYPE_STOCK} from "../../data/constants";

const ProductFunding = forwardRef((props, ref) => {

    const documentTotal = useSelector(state => state.match.documentTotal);
    const documentType = useSelector(state => state.form.documentType);
    const documentText = useSelector(state => getDocumentText(state.form.documentType, "check", "stock certificate"))
    const productFundingsGlobal = useSelector(state => state.form.productFundings);
    const workforceId = useSelector(state => state.form.workforceId);

    const splitRadioOptions = [{id: "y", text: "Yes"}, {id: "n", text: "No"}];

    const [showAllErrors, setShowAllErrors] = useState(false);
    const [depositAmountMatch, setDepositAmountMatch] = useState(true);
    const [isSplitRadio, setSplitRadio] = useState(determineSplit());
    const [productFundings, setProductFundings] = useState([{
        product: {
            clients: [{workforceId: workforceId}],
            workforceId: workforceId
        }, workforceId: workforceId
    }]);
    const dispatch = useDispatch();

    function determineSplit() {
        if (productFundingsGlobal && productFundingsGlobal.length > 1) {
            return 'y';
        }
        return 'n';
    }

    const setProductTypesMatch = (pfs) => {
        let productTypes = pfs.map(pf => pf.product.productType)
        productTypes.every(pt => pt) && actions.updateMatch(dispatch, "productTypes", productTypes)
    }

    function setSpecificProductFunding(index, productFunding) {
        let pfs = [...slice(productFundings, 0, index), productFunding, ...slice(productFundings, index + 1)]
        setProductTypesMatch(pfs)
        setProductFundings(pfs)
    }

    function removeProductFunding(index) {
        setProductFundings([...slice(productFundings, 0, index), ...slice(productFundings, index + 1)]);
    }

    function addAnotherProductFunding() {
        setShowAllErrors(false)
        setProductFundings([...productFundings, {
            product: {
                clients: [{workforceId}],
                workforceId
            },
            workforceId
        }])
    }

    function splitChange(value) {
        if (value === 'n' && ((productFundingsGlobal && productFundingsGlobal.length > 1) || productFundings.length > 1)) {
            setProductFundings(p => [p[0]]);
        }
        if (value === 'y' && productFundings.length === 1) {
            addAnotherProductFunding();
        }
    }

    useEffect(() => {
        if (productFundingsGlobal) {
            setProductFundings(documentType === DOCUMENT_TYPE_STOCK
                ? formatDepositAmounts(productFundingsGlobal, documentTotal, documentType)
                : productFundingsGlobal.map(pf => ({...pf, depositAmount: formatDecimals(pf.depositAmount, 2)}))
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productFundingsGlobal]);



    useEffect(() => {
        if (productFundings.length > 1) {
            const depositAmounts = productFundings.map((productFunding) => {
                return Number(productFunding.depositAmount)
            });

            const totalDepositAmount = roundOff(depositAmounts.reduce((a, b) => a + b), 6)
            if (Number(totalDepositAmount) === Number(documentTotal)) {
                setDepositAmountMatch(true)
            } else {
                setDepositAmountMatch(false)
            }
        } else {
            setDepositAmountMatch(true)
        }
    }, [
        productFundings,
        depositAmountMatch,
        setDepositAmountMatch,
        documentTotal
    ]);

    function productFundingsValid() {
        setShowAllErrors(true)
        if (!depositAmountMatch) {
            return false;
        }
        return productFundings.every(productFunding => productFunding.isValid);
    }

    function fixDepositAmount(pfs) {
        setProductTypesMatch(pfs)
        if (pfs.length === 1) {
            pfs[0].depositAmount = documentTotal
        }
        return pfs;
    }

    function nextPage(event) {
        event.preventDefault();
        if (productFundingsValid()) {
            actions.productFundingsNextPage(dispatch, fixDepositAmount(productFundings));
        }
    }

    function previousPage() {
        actions.productFundingsPreviousPage(dispatch, fixDepositAmount(productFundings));
    }

    function getFields() {
        return [...fixDepositAmount(productFundings)]
    }

    useImperativeHandle(ref, () => ({
        nextPage: nextPage,
        previousPage: previousPage,
        isValid: productFundingsValid,
        getFields: getFields
    }))

    return (
        <>
            <div className="row util-padding-top-10">
                <div className="col-md-3">
                    <span>Is this a split {documentText}?</span>
                </div>
                <div className="col-md-4">
                    <InlineRadio
                        id="isSplitRadio"
                        options={splitRadioOptions}
                        onChange={(x) => {
                            setSplitRadio(x);
                            splitChange(x)
                        }}
                        disabled={props.editable === undefined ? false : !props.editable}
                        defaultValue={isSplitRadio}/>
                </div>
            </div>
            {productFundings.map((productFunding, index) =>
                <div key={index}>
                    <div className={"bg-light-blue util-padding-left-10"}>
                        <ProductInformation
                            id={'productFundings[' + index + ']'}
                            required={productFundings.length === 0}
                            showDepositAmount={productFundings.length > 1}
                            productFunding={productFunding}
                            setProductFunding={setSpecificProductFunding.bind(null, index)}
                            removeProductFunding={removeProductFunding.bind(null, index)}
                            showRemoveProductFunding={productFundings.length > 1 && props.editable}
                            showAllErrors={showAllErrors}
                            setShowAllErrors={setShowAllErrors}
                            editable={props.editable}
                            depositAmountMatch={depositAmountMatch}
                        />
                    </div>
                    <hr role="presentation"/>
                </div>
            )}
            {isSplitRadio === 'y' && (props.editable === undefined ? true : props.editable) &&
            <div className='row util-padding-top-10'>
                <div className='pull-right util-padding-right-15'>
                    <Button text='Add another product' onClick={addAnotherProductFunding}/>
                </div>
            </div>
            }
        </>
    );
})

export default ProductFunding;
