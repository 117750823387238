import React from "react";
import {PropTypes} from "prop-types";
import {useSelector} from "react-redux";
import {getDocumentText} from "../../data/utils";

export default function StepIndicator(props) {
    //todo add accesible step indicator https://docs.principal.com/display/ACCESS/Accessible+Step-Indicators#
    const documentType = useSelector(state => state.form.documentType)
    let pages = [
        'Financial professional',
        `${getDocumentText(documentType)} information`,
        'Products',
        `${getDocumentText(documentType)} history`,
        "Summary"
    ]
    return (
        <div className="row util-padding-top-10">
            <ol className="indicator">
                {pages.map((page, index) => {
                    const activeText = props.currentPageIndex === index ? " horizon-step-indicator-active" : "";
                    const disabledText = props.currentPageIndex < index ? " horizon-step-indicator-disabled" : "";
                    return <li key={page} className={"horizon-step-indicator" + activeText + disabledText}>
                        <span>{page}</span>
                    </li>;
                })}
            </ol>
        </div>
    );
}

StepIndicator.propTypes = {
    currentPageIndex: PropTypes.number.isRequired
};